<template>
  <v-app>
    <v-row>
      <v-app-bar
        app
        flat
        :fixed="$vuetify.breakpoint.mdAndDown"
      >
        <div class="boxed-container w-full">
          <div class="d-flex align-center mx-6">
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"
              v-if="$vuetify.breakpoint.mdAndDown"
            ></v-app-bar-nav-icon>

            <router-link
              to="/landingpage"
              class="d-flex align-center text-decoration-none"
              v-if="!$vuetify.breakpoint.mdAndDown"
            >
              <v-img
                :src="require('@/assets/images/logos/selfbio-logo-6.png')"
                max-height="30px"
                max-width="30px"
                alt="logo"
                contain
                eager
                class="app-logo me-3" style="transform: scale(1.7);opacity: 0.9;"
              ></v-img>
              <v-slide-x-transition>
                <h2 class="app-title text--primary" style="margin-left: 10px">
                  <span style="color: #9155fd;">SELF</span>BIOGRAPHY
                </h2>
              </v-slide-x-transition>
            </router-link>
            <v-spacer></v-spacer>
            <!-- Menu Links -->

            <template v-if="!$vuetify.breakpoint.mdAndDown">
              <router-link
                to="/my-biography"
                class="d-flex align-center text-decoration-none"

                :disabled="!$store.state.logged"
                :event="$store.state.logged ? 'click' : ''"
              >
                <button type="button" class="text-capitalize text-base font-weight-regular px-6 v-btn v-btn--has-bg v-btn--rounded theme--light v-size--large transparent" role="button" aria-haspopup="true" aria-expanded="false">
                  <span class="v-btn__content">
                    <v-icon>
                      {{ icons.mdiBookOpenVariant }}
                    </v-icon>
                    <span>&nbsp;My {{ $store.state.content_type === 1 ? 'Bibliography' : 'Biography' }}</span>

                  </span>
                </button>
              </router-link>

              <router-link
                to="/my-pictures"
                class="d-flex align-center text-decoration-none lnk-my-pictures"

                :disabled="!$store.state.logged"
                :event="$store.state.logged ? 'click' : ''"
              >
                <button type="button" class="text-capitalize text-base font-weight-regular px-6 v-btn v-btn--has-bg v-btn--rounded theme--light v-size--large transparent" role="button" aria-haspopup="true" aria-expanded="false">
                  <span class="v-btn__content">
                    <v-icon>
                      {{ icons.mdiFileImageOutline }}
                    </v-icon>
                    <span>&nbsp;My Pictures</span>
                  </span>
                </button>
              </router-link>

              <router-link
                to="/my-calendar"
                class="d-flex align-center text-decoration-none"

                :disabled="!$store.state.logged || $store.state.level > 1"
                :event="$store.state.logged && $store.state.level === 1 ? 'click' : ''"
              >
                <button type="button" class="text-capitalize text-base font-weight-regular px-6 v-btn v-btn--has-bg v-btn--rounded theme--light v-size--large transparent" role="button" aria-haspopup="true" aria-expanded="false">
                  <span class="v-btn__content">
                    <v-icon>
                      {{ icons.mdiCalendarClockOutline }}
                    </v-icon>
                    <span>&nbsp;My Calendar</span>
                  </span>
                </button>
              </router-link>

              <router-link
                to="my-world"
                class="d-flex align-center text-decoration-none"

                :disabled="!$store.state.logged"
                :event="$store.state.logged ? 'click' : ''"
              >
                <button type="button" class="text-capitalize text-base font-weight-regular px-6 v-btn v-btn--has-bg v-btn--rounded theme--light v-size--large transparent" role="button" aria-haspopup="true" aria-expanded="false">
                  <span class="v-btn__content">
                    <span aria-hidden="true" class="v-icon notranslate v-icon--left theme--dark" style="font-size: 22px; height: 22px; width: 22px;">
                      <v-icon>
                        {{ icons.mdiEarth }}
                      </v-icon>
                    </span>
                    <span>My World</span>
                  </span>
                </button>
              </router-link>

              <router-link
                to="/my-diary"
                class="d-flex align-center text-decoration-none lnk-my-diary"

                :disabled="!$store.state.logged"
                :event="$store.state.logged ? 'click' : ''"
              >
                <button type="button" class="text-capitalize text-base font-weight-regular px-6 v-btn v-btn--has-bg v-btn--rounded theme--light v-size--large transparent" role="button" aria-haspopup="true" aria-expanded="false">
                  <span class="v-btn__content">
                    <span aria-hidden="true" class="v-icon notranslate v-icon--left theme--dark" style="font-size: 22px; height: 22px; width: 22px;">
                      <v-icon>
                        {{ icons.mdiBookOpenPageVariantOutline }}
                      </v-icon>
                    </span>
                    <span>My Diary</span>
                  </span>
                </button>
              </router-link>
              <router-link
                to="/my-will"
                class="d-flex align-center text-decoration-none"

                :disabled="!$store.state.logged || $store.state.level > 1"
                :event="$store.state.logged && $store.state.level === 1 ? 'click' : ''"
              >
                <button type="button" class="text-capitalize text-base font-weight-regular px-6 v-btn v-btn--has-bg v-btn--rounded theme--light v-size--large transparent" role="button" aria-haspopup="true" aria-expanded="false">
                  <span class="v-btn__content">
                    <span aria-hidden="true" class="v-icon notranslate v-icon--left theme--dark" style="font-size: 22px; height: 22px; width: 22px;">
                      <v-icon>
                        {{ icons.mdiEarth }}
                      </v-icon>
                    </span>
                    <span>My Will</span>
                  </span>
                </button>
              </router-link>
              <!-- // Menu Links -->
              <v-spacer></v-spacer>
              <router-link v-if="!$store.state.logged"
                to="/auth"
                class="btn-sign-in"
              >
                <v-btn color="primary">
                  Sign In
                </v-btn>
              </router-link>
            </template>

            <theme-switcher v-if="$store.state.logged"></theme-switcher>
            <v-btn
              icon
              small
              class="ms-3"
              v-if="$store.state.logged"
            >
              <v-icon>
                {{ icons.mdiBellOutline }}
              </v-icon>
            </v-btn>
            <app-bar-user-menu v-if="$store.state.logged"></app-bar-user-menu>
          </div>
        </div>
      </v-app-bar>
    </v-row>

    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
    >
      <v-list-item>
        <v-list-item-content>
          <router-link
            to="/landingpage"
            class="d-flex align-center text-decoration-none"
            :disabled="!$store.state.logged"
            :event="$store.state.logged ? 'click' : ''"
          >
            <v-img
              :src="require('@/assets/images/logos/selfbio-logo-6.png')"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              eager
              class="app-logo me-3" style="transform: scale(1.2);position:relative;left: 10px;opacity: 0.9;"
            ></v-img>
            <v-slide-x-transition>
              <h2 class="app-title text--primary" style="margin-left: 10px">
                <span style="color: #9155fd;">SELF</span>BIOGRAPHY
              </h2>
            </v-slide-x-transition>
          </router-link>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
          link
        >
          <v-list-item-content>
            <router-link
              to="/my-biography"
              class="d-flex align-center text-decoration-none"

              :disabled="!$store.state.logged"
              :event="$store.state.logged ? 'click' : ''"
            >
              <button type="button" class="text-capitalize text-base font-weight-regular px-6 v-btn v-btn--has-bg v-btn--rounded theme--light v-size--large transparent" role="button" aria-haspopup="true" aria-expanded="false">
                <span class="v-btn__content">
                  <v-icon>
                    {{ icons.mdiBookOpenVariant }}
                  </v-icon>
                  <span>&nbsp;My {{ $store.state.content_type === 1 ? 'Bibliography' : 'Biography' }}</span>

                </span>
              </button>
            </router-link>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <router-link
              to="/my-pictures"
              class="d-flex align-center text-decoration-none lnk-my-pictures"

              :disabled="!$store.state.logged"
              :event="$store.state.logged ? 'click' : ''"
            >
              <button type="button" class="text-capitalize text-base font-weight-regular px-6 v-btn v-btn--has-bg v-btn--rounded theme--light v-size--large transparent" role="button" aria-haspopup="true" aria-expanded="false">
                <span class="v-btn__content">
                  <v-icon>
                    {{ icons.mdiFileImageOutline }}
                  </v-icon>
                  <span>&nbsp;My Pictures</span>
                </span>
              </button>
            </router-link>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <router-link
                to="/my-calendar"
                class="d-flex align-center text-decoration-none"

                :disabled="!$store.state.logged || $store.state.level > 1"
                :event="$store.state.logged && $store.state.level === 1 ? 'click' : ''"
              >
                <button type="button" class="text-capitalize text-base font-weight-regular px-6 v-btn v-btn--has-bg v-btn--rounded theme--light v-size--large transparent" role="button" aria-haspopup="true" aria-expanded="false">
                  <span class="v-btn__content">
                    <v-icon>
                      {{ icons.mdiCalendarClockOutline }}
                    </v-icon>
                    <span>&nbsp;My Calendar</span>
                  </span>
                </button>
              </router-link>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <router-link
              to="my-world"
              class="d-flex align-center text-decoration-none"

              :disabled="!$store.state.logged"
              :event="$store.state.logged ? 'click' : ''"
            >
              <button type="button" class="text-capitalize text-base font-weight-regular px-6 v-btn v-btn--has-bg v-btn--rounded theme--light v-size--large transparent" role="button" aria-haspopup="true" aria-expanded="false">
                <span class="v-btn__content">
                  <span aria-hidden="true" class="v-icon notranslate v-icon--left theme--dark" style="font-size: 22px; height: 22px; width: 22px;">
                    <v-icon>
                      {{ icons.mdiEarth }}
                    </v-icon>
                  </span>
                  <span>My World</span>
                </span>
              </button>
            </router-link>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <router-link
              to="/my-diary"
              class="d-flex align-center text-decoration-none lnk-my-diary"

              :disabled="!$store.state.logged"
              :event="$store.state.logged ? 'click' : ''"
            >
              <button type="button" class="text-capitalize text-base font-weight-regular px-6 v-btn v-btn--has-bg v-btn--rounded theme--light v-size--large transparent" role="button" aria-haspopup="true" aria-expanded="false">
                <span class="v-btn__content">
                  <span aria-hidden="true" class="v-icon notranslate v-icon--left theme--dark" style="font-size: 22px; height: 22px; width: 22px;">
                    <v-icon>
                      {{ icons.mdiBookOpenPageVariantOutline }}
                    </v-icon>
                  </span>
                  <span>My Diary</span>
                </span>
              </button>
            </router-link>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <router-link
              to="/my-will"
              class="d-flex align-center text-decoration-none"

              :disabled="!$store.state.logged || $store.state.level > 1"
              :event="$store.state.logged && $store.state.level === 1 ? 'click' : ''"
            >
              <button type="button" class="text-capitalize text-base font-weight-regular px-6 v-btn v-btn--has-bg v-btn--rounded theme--light v-size--large transparent" role="button" aria-haspopup="true" aria-expanded="false">
                <span class="v-btn__content">
                  <span aria-hidden="true" class="v-icon notranslate v-icon--left theme--dark" style="font-size: 22px; height: 22px; width: 22px;">
                    <v-icon>
                      {{ icons.mdiEarth }}
                    </v-icon>
                  </span>
                  <span>My Will</span>
                </span>
              </button>
            </router-link>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <router-link
              to="/auth"
              class="d-flex align-center text-decoration-none"
            >
              <button type="button" class="text-capitalize text-base font-weight-regular px-6 v-btn v-btn--has-bg v-btn--rounded theme--light v-size--large transparent" role="button" aria-haspopup="true" aria-expanded="false">
                <span class="v-btn__content">
                  <span aria-hidden="true" class="v-icon notranslate v-icon--left theme--dark" style="font-size: 22px; height: 22px; width: 22px;">
                    <v-icon>
                      {{ icons.mdiLogout }}
                    </v-icon>
                  </span>
                  <span>{{ $store.state.logged ? 'Logout' : 'Sign In' }}</span>
                </span>
              </button>
            </router-link>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <div class="app-content-container" :class="$route.name !== 'my-world' && $route.name !== 'LandingPage' ? 'boxed-container pa-6' : 'w-full'">
        <slot></slot>
      </div>
    </v-main>

    <v-footer
      app
      inset
      color="white"
      :class="`px-0 ${$route.name}`"
    >
      <Footer></Footer>
    </v-footer>
  </v-app>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiMagnify, mdiBellOutline, mdiGithub, mdiEarth, mdiBookOpenPageVariantOutline, mdiFileImageOutline, mdiCalendarClockOutline, mdiBookOpenVariant, mdiLogout } from '@mdi/js'
// import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import HorizontalNavMenu from './components/vertical-nav-menu/HorizontalNavMenu.vue'
import ThemeSwitcher from './components/ThemeSwitcher.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'
import Footer from '@/layouts/components/Footer.vue';

export default {
  components: {
    HorizontalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
    Footer,
  },
  setup() {
    const isDrawerOpen = ref(null)

    return {
      isDrawerOpen,
      drawer: false,
      // Icons
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
        mdiEarth,
        mdiBookOpenPageVariantOutline,
        mdiFileImageOutline,
        mdiCalendarClockOutline,
        mdiBookOpenVariant,
        mdiLogout
      },
       menuItems: [
        { title: 'Home', path: '/home', icon: 'home' },
        { title: 'Sign Up', path: '/signup', icon: 'face' },
        { title: 'Sign In', path: '/signin', icon: 'lock_open' }
     ]
    }
  },
}
</script>

<style lang="scss" scoped>

.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}
.v-footer {
  position: static !important;
}
.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

a[disabled='disabled']:not(.lnk-my-pictures) button,
a[disabled='disabled']:not(.lnk-my-diary) button {
  color: #ddd;
}
</style>
