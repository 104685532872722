<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800px"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
    >
      <v-card>
        <v-card-title>
          <v-col cols="5" md="5">
            <span class="text-h5">{{ title }}</span>
          </v-col>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-card
                color="grey lighten-1"
            >
              <div class="container">
                <v-form class="multi-col-validation" style="width: 100%;margin: 0 auto">
                  <v-row class="editor-my-diary">
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <wysiwyg v-model="description" tabindex="2" ref="wysiwyg" />
                    </v-col>
                  </v-row>
                </v-form>
              </div>
            </v-card>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="savePage()"
            :loading="loading4"
            :disabled="loading4" color="primary">
            Save
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>{{ mdiCached }}</v-icon>
              </span>
            </template>
          </v-btn>
          <v-btn
            @click="$store.commit('showEditPages', false)">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import axios from "axios";
  import { mdiInformationOutline, mdiCloudUpload, mdiCached, mdiImageSearch } from '@mdi/js';

  export default {
    props: ['page'],
    data () {
      return {
        status: false,
        /* ICONS */
        mdiInformationOutline,
        mdiCloudUpload,
        mdiCached,
        mdiImageSearch,

        loader: null,
        loading4: false,
        user: {
          id: null
        },
        /* -- */
        dialog: true,
        title: '',
        description: '',
        endpoint: process.env.VUE_APP_ROOT_API,
        active: 1,
        logged: true,
        page_id: null,
      }
    },
    components: {
    },
    computed: {
      galleryItems() {
        return this.$store.state.galleryItems;
      }
    },
    watch: {
      galleryItems(val) {
        if(val) {
          this.galleryItems.forEach((value) => {
            document.execCommand("insertHTML", false, `<img src="${value}" style="display: block;width: 80%;margin: 0 auto;" />`);
          });
        }
      },
      loader () {
        const l = this.loader
        this[l] = !this[l]
        setTimeout(() => (this[l] = false), 3000)
        this.loader = null
      },
    },
    mounted() {
      var elm = document.querySelector('[contenteditable]');
      elm.addEventListener('click', () => {
        this.lastPosition = this.getCaretCharacterOffsetWithin(document.querySelector('[contenteditable]'));
        // this.setCaretPosition(document.querySelector('[contenteditable]'), this.lastPosition);
      });

      elm.addEventListener('keydown', () => {
        this.lastPosition = this.getCaretCharacterOffsetWithin(document.querySelector('[contenteditable]'));
      });

      this.getPage(this.page);

      const vwBtnImage = document.querySelector('.vw-btn-image');
      if(vwBtnImage) {
        vwBtnImage.addEventListener('click', (e) => {
          e.preventDefault();
          e.stopPropagation();
          this.callGallery();
        });
      }
    },
    methods: {
      getPage(page) {
        axios.get(`${this.endpoint}/getPage?page_id=${page}`).then(response => {
          this.title = response.data.content[0].title;
          this.description = response.data.content[0].description;
          this.page_id = response.data.content[0].id;
        })
        .catch(() => { /* ERROR */ });
      },
      savePage() {
        this.loader = 'loading4';
        axios.post(`${this.endpoint}/updatePage`, {
          description: this.description,
          page_id: this.page_id,
        }, {
          headers : {
            'Authorization': this.$store.state.jwtToken
          }
        }).then(responseDB => {
          if(this.page_id !== 411) {
            this.$emit('page_id', this.page_id);
          }
          this.$store.commit('showEditPages', false);
        });
      },
      getCaretCharacterOffsetWithin(element) {
        var caretOffset = 0;
        var doc = element.ownerDocument || element.document;
        var win = doc.defaultView || doc.parentWindow;
        var sel;
        if (typeof win.getSelection != "undefined") {
          sel = win.getSelection();
          if (sel.rangeCount > 0) {
              var range = win.getSelection().getRangeAt(0);
              var preCaretRange = range.cloneRange();
              preCaretRange.selectNodeContents(element);
              preCaretRange.setEnd(range.endContainer, range.endOffset);
              caretOffset = preCaretRange.toString().length;
          }
        } else if ( (sel = doc.selection) && sel.type != "Control") {
          var textRange = sel.createRange();
          var preCaretTextRange = doc.body.createTextRange();
          preCaretTextRange.moveToElementText(element);
          preCaretTextRange.setEndPoint("EndToEnd", textRange);
          caretOffset = preCaretTextRange.text.length;
        }
        return caretOffset;
      },
      callGallery() {
        this.$store.commit('showGallery', {
          open: true,
          list: true
        });
      },
      setCaretPosition(el, pos) {
        const self = this;
        for(var node of el.childNodes){
          // if(node.nodeType == 3) { // we have a text node
            if(node.length >= pos) {
              var range = document.createRange(),
                  sel = window.getSelection();
              range.setStart(node,pos);
              range.collapse(true);
              sel.removeAllRanges();
              sel.addRange(range);
              return -1;
            } else {
              pos -= node.length;
            }
        }
        return pos;
      }
    }
  }
</script>

<style scoped lang="scss">
  .icon-info-date {
    position: relative;
    top: -2px;
    left: 5px;
  }

  .txt-title {
    width: 100%;
    // float: right;
  }

  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }

  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
