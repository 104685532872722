import '@/plugins/vue-composition-api';
import '@/styles/styles.scss';
import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import '@aws-amplify/ui-components';
import axios from "axios";

import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';

import wysiwyg from "vue-wysiwyg";
import "vue-wysiwyg/dist/vueWysiwyg.css";

Vue.config.productionTip = false;
import 'maplibre-gl/dist/maplibre-gl.css';

Vue.config.ignoredElements = [/amplify-\w*/];

applyPolyfills().then(() => {
  defineCustomElements(window);
});

Vue.use(wysiwyg, {
  forcePlainTextOnPaste: true,
  hideModules: {
    bold: false,
    italic: false,
    underline: false,
    headings: false,
    code: true,
    image: false,
    table: true,
    removeFormat: false,
    separator: true
  },
});

document.addEventListener('paste', function (e) {
  e.preventDefault();
  document.execCommand("insertHTML", false, e.clipboardData.getData("text/plain"));
});

setTimeout(() => {
  location.reload();
}, 3600000);


axios.interceptors.response.use(undefined, async (err) => {
  const { config, message } = err;

  if(message.includes("401") || message.includes("502")) {
    await store.dispatch("fetchToken");
  }

  if ((!config || !config.retry) && !message.includes("401")) {
    return Promise.reject(err);
  }

  if ((!(message.includes("timeout") || message.includes("Network Error"))) && !message.includes("401")) {
    return Promise.reject(err);
  }

  config.retry -= 1;
  const delayRetryRequest = new Promise((resolve) => {
    setTimeout(() => {
      if(message.includes("401")) {
        config.headers.Authorization = store.state.jwtToken;
      }
      resolve();
    }, config.retryDelay || 1000);
  });

  return delayRetryRequest.then(() => axios(config));
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
