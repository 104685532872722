import Vue from 'vue';
import VueRouter from 'vue-router';
import axios from "axios";
import store from './../store';
import Amplify, { Auth, Hub } from 'aws-amplify';
import awsconfig from './../aws-exports';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'landingpage',
  },

  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/pages/user/Home.vue'),
    meta: { auth: true }
  },

  {
    path: '/landingpage',
    name: 'LandingPage',
    component: () => import('@/views/pages/LandingPage.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/chat',
    name: 'apps-chat',
    component: () => import('@/views/apps/chat/Chat.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/my-biography',
    name: 'my-biography',
    component: () => import('@/views/pages/my-biography/MyBiography.vue'),
    meta: { auth: true }
  },

  {
    path: '/my-pictures',
    name: 'my-pictures',
    component: () => import('@/views/pages/my-pictures/MyPictures.vue'),
    meta: { auth: true }
  },

  {
    path: '/my-calendar',
    name: 'calendar',
    component: () => import('@/views/pages/calendar/Calendar.vue'),
    meta: { auth: true }
  },

  {
    path: '/my-world',
    name: 'my-world',
    component: () => import('@/views/pages/my-world/MyWorld.vue'),
    meta: { auth: true }
  },

  {
    path: '/my-diary',
    name: 'my-diary',
    component: () => import('@/views/pages/my-diary/MyDiary.vue'),
    meta: { auth: true }
  },

  {
    path: '/my-will',
    name: 'my-will',
    component: () => import('@/views/pages/my-will/MyWill.vue'),
    meta: { auth: true }
  },

  {
    path: '/account-settings',
    name: 'pages-account-settings',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
    meta: { auth: true }
  },

  {
    path: '/auth',
    name: 'pages-login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/user/:username',
    name: 'public_user',
    component: () => import('../views/pages/user/UserContent.vue')
  },

  {
    path: '/pages/pricing',
    name: 'page-pricing',
    component: () => import('@/views/pages/pricing/Pricing.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/page/:page_id',
    name: 'public_pages',
    component: () => import('../views/pages/content/Pages.vue')
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

Amplify.configure(awsconfig);

let user;

function getUser() {
  localStorage.removeItem('user_active');
  localStorage.removeItem('user_name');
  return Auth.currentAuthenticatedUser()
    .then((data) => {
      if (data && data.signInUserSession) {
        store.commit('setToken', data.signInUserSession.accessToken.jwtToken);
        axios.get(`${process.env.VUE_APP_ROOT_API}/getUser`, {
          headers : {
            'Authorization': store.state.jwtToken
          }
        }).then(response => {
          if (response.data.content.length) {
            localStorage.setItem('user_name', data.attributes.name);
            localStorage.setItem('user_active', response.data.content[0].is_active);
            localStorage.setItem('is_public', response.data.content[0].public);
            localStorage.setItem('level', response.data.content[0].level);
            localStorage.setItem('agreements', response.data.content[0].agreements);
            store.commit('setPublic', response.data.content[0].public);
            store.commit('setLevel', response.data.content[0].level);
            store.commit('setUser', { extra_data: response.data.content[0] });
          } else {
            localStorage.setItem('user_id', null);
            axios.post(`${process.env.VUE_APP_ROOT_API}/insertUser`, {
              name: data.attributes.name,
              email: data.attributes.email
            }, {
              headers : {
                'Authorization': store.state.jwtToken
              }
            }).then(responseDB => {
              localStorage.setItem('user_name', data.attributes.name);
              store.commit('setLevel', 2);
              store.commit('reloadCategories');
              store.commit('setUser', { extra_data: responseDB.data });
            });
          }
        });
      return data;
    }
  }).catch(() => {
    store.commit('setUser', null);
    return null;
  });
}

Hub.listen("auth", async (data) => {
  if (data.payload.event === 'signOut'){
    user = null;
    store.commit('setUser', null);
    localStorage.removeItem('user_name');
    localStorage.removeItem('user_active');
    store.commit('setLogin', false);
    store.commit('setLevel', 2);
    if(location.href.indexOf('/user/') === -1) {
      router.push({path: '/auth'}).catch(()=>{});
    }
  } else if (data.payload.event === 'signIn') {
    localStorage.removeItem('user_name');
    localStorage.removeItem('user_active');
    store.commit('setLogin', true);
    router.push({path: '/my-pictures'}).catch(()=>{});
  }
});


// Auth.signOut();
router.beforeResolve((to, from, next) => {
  if(to.meta.auth) {
    Auth.currentAuthenticatedUser().then(async (data) => {
      if(!store.state.user) {
        user = await getUser();
      }
      store.commit('setToken', data.signInUserSession.accessToken.jwtToken);
      localStorage.setItem('refreshToken', data.signInUserSession.refreshToken.token);
      store.commit('setLogin', true);
      store.commit('setLevel', localStorage.getItem('level'));
      if(!parseInt(localStorage.getItem('agreements'), 10)) {
        store.commit('setAgreements', true);
      }
      next();
    }).catch((data) => {
      console.log('User is not authenticated', data);
      if(to.name !== 'public_pages' && to.name !== 'public_user') {
        next({
          path: '/auth'
        });
        localStorage.removeItem('user_active');
        localStorage.removeItem('is_public');
        localStorage.removeItem('user_name');
      }
    });
  } else {
    next();
  }
});

export default router
