<template>
    <v-row justify="center">
      <v-dialog
      v-model="dialog"
      persistent
      width="70vw"
      max-width="1360px"
      class="modal-agreements"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Self Biography - User Agreements</span>
        </v-card-title>
        <v-card-text class="wrapper-terms" v-html="content[0].description">
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="updateUser(false)"
          >
            Disagree
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>{{ mdiCached }}</v-icon>
              </span>
            </template>
          </v-btn>
          <v-btn
              color="primary"
              @click="updateUser(true)"
          >
            Agree
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>{{ mdiCached }}</v-icon>
              </span>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import axios from "axios";
  import { mdiCached } from '@mdi/js';

  export default {
    data () {
      return {
        dialog: true,
        endpoint: process.env.VUE_APP_ROOT_API,
        content: [{
          title: '',
          description: '',
        }]
      }
    },
    mounted() {
      // this.checkLogin();
      this.getPage(411);
    },
    methods: {
      getPage(page) {
        axios.get(`${this.endpoint}/getPage?page_id=${page}`).then(response => {
          this.content = response.data.content;
        })
        .catch(() => { /* ERROR */ });
      },
      updateUser(status){
        this.loader = 'loading4';
        this.$store.commit('setAgreements', false);
        localStorage.setItem('agreements', status ? 1 : 0);
        axios.post(`${this.endpoint}/updateUserData`, {
          user_agreements: status,
        }, {
          headers : {
            'Authorization': this.$store.state.jwtToken
          }
        }).then(responseDB => {
          this.$store.commit('setAgreements', false);
          localStorage.setItem('agreements', status ? 1 : 0);
        });
      },
    }
  }
</script>

<style lang="scss">
  .wrapper-terms {
    max-height: 680px;
    overflow: hidden;
    overflow-y: auto;
    height: 70vh;
    border: 1px solid #ccc;
    border-left: 0;
    border-right: 0;
    h6 {
      margin: 10px 0;
    }
  }
</style>
