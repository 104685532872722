<template>
    <div id="file-drag-drop">
      <form id="drop-form" @drop="handleFileDrop( $event )" v-show="!files.length">
        <span class="drop-files">Drop the files here!</span>
      </form>
      <form id="slider-drop" @drop="handleFileDrop( $event )" v-show="files.length">
        <v-item-group multiple>
          <v-container>
            <v-row :class="{'drag-disabled': !editing}">
              <draggable
                class="wrapper-drag"
                tag="div"
                v-model="files"
                v-bind="dragOptions"
                @start="isDragging = true"
                @end="reOrder()"
              >
                <transition-group type="transition" name="flip-list">
                  <v-col
                    v-for="(file, key) in files"
                    :key="`drag-${key}`"
                    cols="12"
                    md="4"
                    class="list-group-item"
                  >
                    <v-item>
                      <v-card
                        class="d-flex align-center img-container"
                        dark
                        height="190"
                      >
                        <v-icon dark :class="{'alternate-icon-small': !mdiClose}" class="mx-auto btn-remove" @click="removeFile(key)" v-if="files.length">
                          {{ mdiClose }}
                        </v-icon>
                        <img class="preview drag-and-drop-preview" v-bind:id="'drag-and-drop-preview-'+parseInt( key )" :class="`rotate_images__position_${rotatePosition.length ? rotatePosition[key] : ''}`" />
                        <v-icon dark class="mx-auto btn-rotate" @click="rotateImage(key)">
                          {{ mdiCropRotate }}
                        </v-icon>
                      </v-card>
                    </v-item>
                  </v-col>
                </transition-group>
              </draggable>
            </v-row>
          </v-container>
        </v-item-group>
      </form>

      <v-row>
        <v-col
          md="8"
          sm="12"
        >
        <label>
          <input type="file" class="file-uploader" multiple @change="handleFileUpload( $event )" />
          <v-btn
              color="primary"
              class="mt-5 alight-center"
              @click="callFileUploader()"
          >
            {{ relatedFiles ? 'Relate more images&nbsp;&nbsp;' : 'Click to upload&nbsp;&nbsp;' }}
            <v-icon
                right
                dark
                :class="{'alternate-icon-small': !mdiCloudUpload}"
                class="mx-auto"
            >
                {{ mdiCloudUpload }}
            </v-icon>
          </v-btn>
        </label>
        <v-tooltip top color="#000000" max-width="200px" v-if="!$vuetify.breakpoint.mdAndDown">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              :class="{'alternate-icon-small': !mdiInformationOutline}"
              class="mx-auto icon-relate"
              title="Relate more images?"
              v-bind="attrs"
              v-on="on"
            >
              {{ mdiInformationOutline }}
            </v-icon>
          </template>
          <span>Click to upload more pictures on drag into the gray box...</span>
        </v-tooltip>
      </v-col>
      <v-col  md="4" sm="12" v-if="$store.state.diary || $store.state.modal_edit || $store.state.edit_pages || $store.state.modal_upload">
        <v-btn color="primary" :class="{'alight-right btn-gallery mt-5': !$vuetify.breakpoint.mdAndDown}" @click="callGallery()">
          Choose from Gallery&nbsp;&nbsp;
          <v-icon dark :class="{'alternate-icon-small': !mdiImageSearch}" class="mx-auto">{{ mdiImageSearch }}</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import {
    mdiCloudUpload, mdiClose, mdiInformationOutline, mdiImageSearch, mdiCropRotate
} from '@mdi/js';

export default {
    props: ['relatedFiles', 'file', 'editing', 'rotatePositionProp'],
    data(){
      return {
        dragAndDropCapable: false,
        files: [],
        uploadPercentage: 0,
        resultImage: '',
        mimeType: null,
        mdiCloudUpload,
        mdiClose,
        mdiInformationOutline,
        mdiImageSearch,
        mdiCropRotate,
        isDragging: false,
        rotatePosition: [],
      }
    },
    components: {
      draggable,
    },
    computed: {
      fileStatus() {
        return this.$store.state.resetUpload;
      },
      galleryItems() {
        return this.$store.state.galleryItems;
      },
      dragOptions() {
        return {
          animation: 1,
          group: "description",
          disabled: !this.editing ? true : false,
          ghostClass: "ghost"
        };
      }
    },
    watch: {
      galleryItems(val) {
        if(val) {
          val.forEach((value) => {
            this.files.push(value);
          });
          this.getImagePreviews();
        }
      },
      fileStatus(value) {
        if(value) {
          this.removeFile(0);
        }
      }
    },
    beforeMount() {
      if(!this.rotatePositionProp) {
        this.rotatePosition = [];
      } else {
        this.rotatePosition = this.rotatePositionProp;
      }
    },
    mounted() {
      if(this.editing) {
        this.files = this.relatedFiles;
        this.getImagePreviews();
      }

      /*
          Determine if drag and drop functionality is capable in the browser
      */
      this.dragAndDropCapable = this.determineDragAndDropCapable();

      /*
          If drag and drop capable, then we continue to bind events to our elements.
      */
      if( this.dragAndDropCapable ){
          this.bindEvents();
      }
    },

    methods: {
      rotateImage(key) {
        const position = parseInt(this.rotatePosition[key], 10) === 3 ? 0 : parseInt(this.rotatePosition[key], 10) + 1;
        this.$set(this.rotatePosition, key, position);
      },
      reOrder() {
        this.isDragging = false;
        this.getImagePreviews();
      },
      callGallery() {
        this.$store.commit('showGallery', {
          open: true,
          list: true
        });
      },
      callFileUploader() {
        document.querySelector('.file-uploader').click();
      },
      handleFileUpload( event ){
        for( let i = 0; i < event.target.files.length; i++ ) {
          if(event.target.files[i]) {
            this.files.push(event.target.files[i]);
          }
        }
        this.getImagePreviews();
      },
      bindEvents(){
          /*
              Listen to all of the drag events and bind an event listener to each
              for the fileform.
          */
        ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach( function( evt ) {
            /*
                For each event add an event listener that prevents the default action
                (opening the file in the browser) and stop the propagation of the event (so
                no other elements open the file in the browser)
            */
            document.getElementById('drop-form').addEventListener(evt, function(e){
              e.preventDefault();
              e.stopPropagation();
            }.bind(this), false);
            document.getElementById('slider-drop').addEventListener(evt, function(e){
              if(evt !== 'dragstart') {
                e.preventDefault();
                e.stopPropagation();
              }
            }.bind(this), false);
        }.bind(this));
      },

      handleFileDrop( event ){
        for( let i = 0; i < event.dataTransfer.files.length; i++ ){
          this.files.push( event.dataTransfer.files[i] );
        }
        this.getImagePreviews();
      },

      determineDragAndDropCapable(){
        /*
            Create a test element to see if certain events
            are present that let us do drag and drop.
        */
        var div = document.createElement('div');
        /*
            Check to see if the `draggable` event is in the element
            or the `ondragstart` and `ondrop` events are in the element. If
            they are, then we have what we need for dragging and dropping files.

            We also check to see if the window has `FormData` and `FileReader` objects
            present so we can do our AJAX uploading
        */
        return ( ( 'draggable' in div )
                        || ( 'ondragstart' in div && 'ondrop' in div ) )
                        && 'FormData' in window
                        && 'FileReader' in window;
      },
      getImagePreviews() {
        /*
          Iterate over all of the files and generate an image preview for each one.
        */
        const self = this;
        let arrayEmit = [];
        for( let i = 0; i < this.files.length; i++ ){
          /* Ensure the file is an image file */
          if(typeof this.files[i] === 'object' && this.files[i] !== null) {
            if ( /\.(jpe?g|png|gif|webp|heic|mov|mpg|mpeg|mp4|wmv|avi|webm)$/i.test( this.files[i].name ) ) {
              /* Create a new FileReader object */
              let reader = new FileReader();
              /*
                  Add an event listener for when the file has been loaded
                  to update the src on the file preview.
              */
              reader.addEventListener("load", function(){
                document.getElementById('drag-and-drop-preview-'+parseInt(i)).src = reader.result;
                self.resultImage = reader.result;
                self.mimeType = self.resultImage.split(';base64')[0].replace('data:', '');
                this.rotatePosition.push(0);
                arrayEmit.push({
                  name: self.files[i].name,
                  file: self.resultImage,
                  mimeType: self.mimeType,
                })
              }.bind(this), false);

              /*
                  Read the data for the file in through the reader. When it has
                  been loaded, we listen to the event propagated and set the image
                  src to what was loaded from the reader.
              */
              reader.readAsDataURL( this.files[i] );
            }
          } else {
            this.$nextTick(function() {
              arrayEmit.push(self.files[i]);
              this.rotatePosition[i] = this.rotatePosition[i] ? this.rotatePosition[i] : 0;
              document.getElementById('drag-and-drop-preview-'+parseInt(i)).src = `${self.files[i]}`;
            });
          }
        }
        this.$emit('filed', arrayEmit);
        this.$emit('rotation_position', this.rotatePosition);
      },
      removeFile( key ){
        this.files.splice( key, 1 );
        this.rotatePosition.splice( key, 1 );
        this.getImagePreviews();
      },
    }
}
</script>

<style scoped lang="scss">
form {
  display: block;
  width: 100%;
  background: #ccc;
  margin: auto;
  text-align: center;
  max-height: 430px;
  border-radius: 4px;
  height: 50vh;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  overflow-y: scroll;

  @media(max-width: 940px) {
    max-height: 250px;
  }

  .drop-files {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media(max-width: 940px) {
  .v-card {
    .v-btn  {
      display: block;
      margin: 0 auto;
    }
  }
}

div.file-listing {
  width: 100%;
  margin: auto;
  padding: 10px;
  height: 100vh;
  max-height: 500px;
}

div.file-listing img{
  width: auto;
  height: 95%;
  display: block;
  margin: 0 auto;
}

div.remove-container{
  text-align: center;
}

div.remove-container a{
  color: red;
  cursor: pointer;
}

a.submit-button{
  display: block;
  margin: auto;
  text-align: center;
  width: 200px;
  padding: 10px;
  text-transform: uppercase;
  background-color: #CCC;
  color: white;
  font-weight: bold;
  margin-top: 20px;
}

progress{
  width: 100%;
  margin: auto;
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
}

input[type="file"] {
  opacity: 0;
  position: absolute;
  left: -999999px;
}
.v-item-group {
  overflow: hidden;
  overflow-y: auto;
  height: 420px;
  .wrapper-image {
    padding: 5px 2.5px;
  }

  .img-container {
    overflow: hidden;
    border-radius: 5px;
  }
  img {
    width: 100%;
    height: auto;
  }
}

.btn-remove, .chk-images,
.btn-rotate {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  padding: 0px;
  background: rgba(0,0,0,0.8);
  border-radius: 100% !important;
}

.btn-rotate {
  right: auto;
  left: 50%;
  top: auto;
  bottom: 5px;
  transform: translateX(-50%);
  padding: 5px;
}

.chk-images {
  right: auto;
  left: 10px;
  background: none;
  top: 50% !important;
  transform: translateY(-50%)
}

.icon-relate {
  position: relative;
  top: 11px;
  left: 5px;
}

.wrapper-drag {
  > span {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    margin: 12px;
  }

  .item {
    padding: 0;
    margin: 0;
    border-width: thin;
    position: relative;
    cursor: move;
    display: flex;
    align-items: center;
    border-color: #312d4b;
    color: rgba(231, 227, 252, 0.68);
    background-color: #312d4b;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 2px 10px 0 rgba(94, 86, 105, 0.1);
    height: 190px;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;

    img {
      line-height: 1.5;
      text-align: center;
      overflow-wrap: break-word;
      cursor: pointer;
      width: 200px;
      height: auto;
      display: block;
    }
  }
}

.btn-gallery {
  float: right;
}

/* DRAG ITEMS */
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
  padding: 2.5px;
}

.list-group-item i {
  cursor: pointer;
}
</style>
