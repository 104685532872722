/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:970400ca-ec16-4760-ae02-85932c41c22b",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_VNSwPexVf",
    "aws_user_pools_web_client_id": "dkich7t8jtuoga1e3e86ac05t",
    "oauth": {
        "domain": "83ud0rl0h6sm-staging.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://beta.selfbiography.com/",
        "redirectSignOut": "https://beta.selfbiography.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "geo": {
        "amazon_location_service": {
            "region": "us-east-2",
            "search_indices": {
                "items": [
                    "SelfBiographySearch-staging"
                ],
                "default": "SelfBiographySearch-staging"
            },
            "maps": {
                "items": {
                    "SelfBiography-staging": {
                        "style": "VectorHereExplore"
                    }
                },
                "default": "SelfBiography-staging"
            }
        }
    }
};


export default awsmobile;
