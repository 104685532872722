var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"footer"},[_c('div',{staticClass:"container boxed-container w-full"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h2',{staticClass:"app-title text--primary"},[_c('v-img',{staticClass:"app-logo me-3",staticStyle:{"transform":"scale(1.7)","opacity":"0.9"},attrs:{"src":require('@/assets/images/logos/selfbio-logo-6.png'),"max-height":"30px","max-width":"30px","alt":"logo","contain":"","eager":""}}),_vm._m(0)],1)]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"social-links"},[_c('a',{attrs:{"href":"javascript:;"}},[_c('v-tooltip',{attrs:{"top":"","color":"#000000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mx-auto",class:{'alternate-icon-small': !_vm.mdiFacebook}},on),[_vm._v(" "+_vm._s(_vm.mdiFacebook)+" ")])]}}])},[_c('span',[_vm._v(" Coming Soon ")])])],1),_c('a',{attrs:{"href":"javascript:;"}},[_c('v-tooltip',{attrs:{"top":"","color":"#000000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mx-auto",class:{'alternate-icon-small': !_vm.mdiTwitter}},on),[_vm._v(" "+_vm._s(_vm.mdiTwitter)+" ")])]}}])},[_c('span',[_vm._v(" Coming Soon ")])])],1),_c('a',{attrs:{"href":"javascript:;"}},[_c('v-tooltip',{attrs:{"top":"","color":"#000000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mx-auto",class:{'alternate-icon-small': !_vm.mdiInstagram}},on),[_vm._v(" "+_vm._s(_vm.mdiInstagram)+" ")])]}}])},[_c('span',[_vm._v(" Coming Soon ")])])],1),_c('a',{attrs:{"href":"javascript:;"}},[_c('v-tooltip',{attrs:{"top":"","color":"#000000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mx-auto",class:{'alternate-icon-small': !_vm.mdiLinkedin}},on),[_vm._v(" "+_vm._s(_vm.mdiLinkedin)+" ")])]}}])},[_c('span',[_vm._v(" Coming Soon ")])])],1),_c('a',{attrs:{"href":"javascript:;"}},[_c('v-tooltip',{attrs:{"top":"","color":"#000000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mx-auto",class:{'alternate-icon-small': !_vm.mdiTelegram}},on),[_vm._v(" "+_vm._s(_vm.mdiTelegram)+" ")])]}}])},[_c('span',[_vm._v(" Coming Soon ")])])],1)])])])])])]),_c('hr'),_c('div',{staticClass:"container row wrapper-menu-footer"},[_c('div',{staticClass:"footer-col"},[_c('h4',{staticClass:"text-overline"},[_vm._v("About")]),_c('ul',[_c('li',[_c('a',{attrs:{"href":"javascript:;"}},[_c('v-tooltip',{attrs:{"right":"","color":"#000000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v("Self Biography")]),(_vm.$store.state.level == 1)?_c('v-icon',{staticClass:"mx-auto",on:{"click":function($event){return _vm.editPage(400)}}},[_vm._v(_vm._s(_vm.mdiPlaylistEdit))]):_vm._e()]}}])},[_c('span',[_vm._v(" Coming Soon ")])])],1)]),_c('li',[_c('a',{attrs:{"href":"javascript:;"}},[_c('v-tooltip',{attrs:{"right":"","color":"#000000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v("Credits and Gratitude")]),(_vm.$store.state.level == 1)?_c('v-icon',{staticClass:"mx-auto",on:{"click":function($event){return _vm.editPage(401)}}},[_vm._v(_vm._s(_vm.mdiPlaylistEdit))]):_vm._e()]}}])},[_c('span',[_vm._v(" Coming Soon ")])])],1)]),_c('li',[_c('a',{attrs:{"href":"javascript:;"}},[_c('v-tooltip',{attrs:{"right":"","color":"#000000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v("The Company")]),(_vm.$store.state.level == 1)?_c('v-icon',{staticClass:"mx-auto",on:{"click":function($event){return _vm.editPage(402)}}},[_vm._v(_vm._s(_vm.mdiPlaylistEdit))]):_vm._e()]}}])},[_c('span',[_vm._v(" Coming Soon ")])])],1)]),_c('li',[_c('a',{attrs:{"href":"javascript:;"}},[_c('v-tooltip',{attrs:{"right":"","color":"#000000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v("Security")]),(_vm.$store.state.level == 1)?_c('v-icon',{staticClass:"mx-auto",on:{"click":function($event){return _vm.editPage(403)}}},[_vm._v(_vm._s(_vm.mdiPlaylistEdit))]):_vm._e()]}}])},[_c('span',[_vm._v(" Coming Soon ")])])],1)]),_c('li',[_c('a',{attrs:{"href":"javascript:;"}},[_c('v-tooltip',{attrs:{"right":"","color":"#000000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v("Web3 / Blockchain")]),(_vm.$store.state.level == 1)?_c('v-icon',{staticClass:"mx-auto",on:{"click":function($event){return _vm.editPage(404)}}},[_vm._v(_vm._s(_vm.mdiPlaylistEdit))]):_vm._e()]}}])},[_c('span',[_vm._v(" Coming Soon ")])])],1)]),_c('li',[_c('a',{attrs:{"href":"javascript:;"}},[_c('v-tooltip',{attrs:{"right":"","color":"#000000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v("Token")]),(_vm.$store.state.level == 1)?_c('v-icon',{staticClass:"mx-auto",on:{"click":function($event){return _vm.editPage(405)}}},[_vm._v(_vm._s(_vm.mdiPlaylistEdit))]):_vm._e()]}}])},[_c('span',[_vm._v(" Coming Soon ")])])],1)])])]),_c('div',{staticClass:"footer-col"},[_c('h4',{staticClass:"text-overline"},[_vm._v("Resources")]),_c('ul',[_c('li',[_c('a',{attrs:{"href":"javascript:;"}},[_c('span',{on:{"click":function($event){return _vm.$store.commit('setAgreements', true);}}},[_vm._v("User Agreements")]),(_vm.$store.state.level == 1)?_c('v-icon',{staticClass:"mx-auto",on:{"click":function($event){return _vm.editPage(411)}}},[_vm._v(_vm._s(_vm.mdiPlaylistEdit))]):_vm._e()],1)]),_c('li',[_c('a',{attrs:{"href":"javascript:;"}},[_c('v-tooltip',{attrs:{"right":"","color":"#000000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v("Documentation")]),(_vm.$store.state.level == 1)?_c('v-icon',{staticClass:"mx-auto",on:{"click":function($event){return _vm.editPage(406)}}},[_vm._v(_vm._s(_vm.mdiPlaylistEdit))]):_vm._e()]}}])},[_c('span',[_vm._v(" Coming Soon ")])])],1)]),_c('li',[_c('a',{attrs:{"href":"javascript:;"}},[_c('v-tooltip',{attrs:{"right":"","color":"#000000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v("FAQ")]),(_vm.$store.state.level == 1)?_c('v-icon',{staticClass:"mx-auto",on:{"click":function($event){return _vm.editPage(407)}}},[_vm._v(_vm._s(_vm.mdiPlaylistEdit))]):_vm._e()]}}])},[_c('span',[_vm._v(" Coming Soon ")])])],1)]),_c('li',[_c('a',{attrs:{"href":"javascript:;"}},[_c('v-tooltip',{attrs:{"right":"","color":"#000000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v("Support")]),(_vm.$store.state.level == 1)?_c('v-icon',{staticClass:"mx-auto",on:{"click":function($event){return _vm.editPage(408)}}},[_vm._v(_vm._s(_vm.mdiPlaylistEdit))]):_vm._e()]}}])},[_c('span',[_vm._v(" Coming Soon ")])])],1)]),_c('li',[_c('a',{attrs:{"href":"javascript:;"}},[_c('v-tooltip',{attrs:{"right":"","color":"#000000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v("Metamask")]),(_vm.$store.state.level == 1)?_c('v-icon',{staticClass:"mx-auto",on:{"click":function($event){return _vm.editPage(409)}}},[_vm._v(_vm._s(_vm.mdiPlaylistEdit))]):_vm._e()]}}])},[_c('span',[_vm._v(" Coming Soon ")])])],1)])])]),_c('div',{staticClass:"footer-col"},[_c('h4',{staticClass:"text-overline"},[_vm._v("Pricing")]),_c('ul',[_vm._m(1),_c('li',[_c('a',{attrs:{"href":"javascript:;"}},[_c('v-tooltip',{attrs:{"right":"","color":"#000000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v("Crypto Plans")]),(_vm.$store.state.level == 1)?_c('v-icon',{staticClass:"mx-auto",on:{"click":function($event){return _vm.editPage(414)}}},[_vm._v(_vm._s(_vm.mdiPlaylistEdit))]):_vm._e()]}}])},[_c('span',[_vm._v(" Coming Soon ")])])],1)])])]),_c('div',{staticClass:"footer-col"},[_c('h4',{staticClass:"text-overline"},[_vm._v("Social")]),_c('ul',[_c('li',[_c('a',{attrs:{"href":"javascript:;"}},[_c('v-tooltip',{attrs:{"right":"","color":"#000000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v("Facebook")])]}}])},[_c('span',[_vm._v(" Coming Soon ")])])],1)]),_c('li',[_c('a',{attrs:{"href":"javascript:;"}},[_c('v-tooltip',{attrs:{"right":"","color":"#000000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v("Twitter")])]}}])},[_c('span',[_vm._v(" Coming Soon ")])])],1)]),_c('li',[_c('a',{attrs:{"href":"javascript:;"}},[_c('v-tooltip',{attrs:{"right":"","color":"#000000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v("LinkedIn")])]}}])},[_c('span',[_vm._v(" Coming Soon ")])])],1)]),_c('li',[_c('a',{attrs:{"href":"javascript:;"}},[_c('v-tooltip',{attrs:{"right":"","color":"#000000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v("Telegram")])]}}])},[_c('span',[_vm._v(" Coming Soon ")])])],1)]),_c('li',[_c('a',{attrs:{"href":"javascript:;"}},[_c('v-tooltip',{attrs:{"right":"","color":"#000000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v("Discord")])]}}])},[_c('span',[_vm._v(" Coming Soon ")])])],1)])])]),(_vm.$store.state.edit_pages && _vm.$store.state.level == 1)?_c('ModalPages',{attrs:{"page":_vm.page},on:{"page_id":_vm.callPage}}):_vm._e(),(_vm.$store.state.agreements)?_c('Agreements',{staticClass:"mb-6"}):_vm._e(),(_vm.$store.state.gallery.open)?_c('ModalGallery',{staticClass:"mb-6"}):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticStyle:{"margin-left":"12px","padding-top":"7px","display":"inline-block"}},[_c('span',{staticStyle:{"color":"#9155fd"}},[_vm._v("SELF")]),_vm._v("BIOGRAPHY")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('a',{attrs:{"href":"/pages/pricing"}},[_vm._v("Plans")])])}]

export { render, staticRenderFns }