<template>
  <div class="v-toolbar__content" style="height: 64px;position: relative;top: 75px;">
    <div class="horizontal-nav-menu theme--light horizontal-nav-menu-boxed mx-auto">
      <button type="button" class="text-capitalize text-base font-weight-regular px-6 v-btn v-btn--has-bg v-btn--rounded theme--light v-size--large transparent" role="button" aria-haspopup="true" aria-expanded="false">
        <span class="v-btn__content">
          <span aria-hidden="true" class="v-icon notranslate v-icon--left theme--dark" style="font-size: 22px; height: 22px; width: 22px;">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg" style="font-size: 22px; height: 22px; width: 22px;">
              <path d="M12 5.69L17 10.19V18H15V12H9V18H7V10.19L12 5.69M12 3L2 12H5V20H11V14H13V20H19V12H22L12 3Z"></path>
            </svg>
          </span>
          <span>Dashboards</span>
          <span aria-hidden="true" class="v-icon notranslate theme--light" style="font-size: 20px; height: 20px; width: 20px;">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg" style="font-size: 20px; height: 20px; width: 20px;">
              <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"></path>
            </svg>
          </span>
        </span>
      </button>
      <!-- <nav-menu-link
        title="Dashboard"
        :to="{ name: 'dashboard' }"
        :icon="icons.mdiHomeOutline"
        color="transparent"
        rounded
        dark
      ></nav-menu-link> -->
      <div class="v-menu horizontal-nav-header-group v-menu--attached" eagers="">
        <div role="menu" class="v-menu__content theme--light list-style elevation-0" style="min-width: 188px; top: 53px; left: 281px; transform-origin: left top; z-index: 8; display: none;">
          <div class="v-list v-sheet theme--light elevation-8">
            <div role="listbox" class="v-item-group theme--light v-list-item-group primary--text">
              <a href="/materio-vuetify-vuejs-admin-template/demo-5/dashboards/crm" class="v-list-item v-list-item--link theme--light" tabindex="0" role="option" aria-selected="false">
                <div class="v-list-item__icon">
                  <span aria-hidden="true" class="v-icon notranslate theme--light" style="font-size: 22px; height: 22px; width: 22px;">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg" style="font-size: 22px; height: 22px; width: 22px;">
                      <path d="M21,16V4H3V16H21M21,2A2,2 0 0,1 23,4V16A2,2 0 0,1 21,18H14V20H16V22H8V20H10V18H3C1.89,18 1,17.1 1,16V4C1,2.89 1.89,2 3,2H21M5,6H14V11H5V6M15,6H19V8H15V6M19,9V14H15V9H19M5,12H9V14H5V12M10,12H14V14H10V12Z"></path>
                    </svg>
                  </span>
                </div>
                <div class="v-list-item__content">
                  <div class="v-list-item__title">CRM</div>
                </div>
              </a>
              <a href="/materio-vuetify-vuejs-admin-template/demo-5/dashboards/analytics" class="v-list-item v-list-item--link theme--light" tabindex="0" role="option" aria-selected="false">
                <div class="v-list-item__icon">
                  <span aria-hidden="true" class="v-icon notranslate theme--light" style="font-size: 22px; height: 22px; width: 22px;">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg" style="font-size: 22px; height: 22px; width: 22px;">
                      <path d="M3,14L3.5,14.07L8.07,9.5C7.89,8.85 8.06,8.11 8.59,7.59C9.37,6.8 10.63,6.8 11.41,7.59C11.94,8.11 12.11,8.85 11.93,9.5L14.5,12.07L15,12C15.18,12 15.35,12 15.5,12.07L19.07,8.5C19,8.35 19,8.18 19,8A2,2 0 0,1 21,6A2,2 0 0,1 23,8A2,2 0 0,1 21,10C20.82,10 20.65,10 20.5,9.93L16.93,13.5C17,13.65 17,13.82 17,14A2,2 0 0,1 15,16A2,2 0 0,1 13,14L13.07,13.5L10.5,10.93C10.18,11 9.82,11 9.5,10.93L4.93,15.5L5,16A2,2 0 0,1 3,18A2,2 0 0,1 1,16A2,2 0 0,1 3,14Z"></path>
                    </svg>
                  </span>
                </div>
                <div class="v-list-item__content">
                  <div class="v-list-item__title">Analytics</div>
                </div>
              </a>
              <a href="/materio-vuetify-vuejs-admin-template/demo-5/dashboards/eCommerce" class="v-list-item v-list-item--link theme--light" tabindex="0" role="option" aria-selected="false">
                <div class="v-list-item__icon">
                  <span aria-hidden="true" class="v-icon notranslate theme--light" style="font-size: 22px; height: 22px; width: 22px;">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg" style="font-size: 22px; height: 22px; width: 22px;">
                      <path d="M17,18A2,2 0 0,1 19,20A2,2 0 0,1 17,22C15.89,22 15,21.1 15,20C15,18.89 15.89,18 17,18M1,2H4.27L5.21,4H20A1,1 0 0,1 21,5C21,5.17 20.95,5.34 20.88,5.5L17.3,11.97C16.96,12.58 16.3,13 15.55,13H8.1L7.2,14.63L7.17,14.75A0.25,0.25 0 0,0 7.42,15H19V17H7C5.89,17 5,16.1 5,15C5,14.65 5.09,14.32 5.24,14.04L6.6,11.59L3,4H1V2M7,18A2,2 0 0,1 9,20A2,2 0 0,1 7,22C5.89,22 5,21.1 5,20C5,18.89 5.89,18 7,18M16,11L18.78,6H6.14L8.5,11H16Z"></path>
                    </svg>
                  </span>
                </div>
                <div class="v-list-item__content">
                  <div class="v-list-item__title">eCommerce</div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="v-menu horizontal-nav-header-group v-menu--attached" eagers="">
        <!---->
      </div>
      <button type="button" class="text-capitalize text-base font-weight-regular px-6 v-btn v-btn--has-bg v-btn--rounded theme--light v-size--large primary bg-gradient-primary group-activator-active" role="button" aria-haspopup="true" aria-expanded="false">
        <span class="v-btn__content">
          <span aria-hidden="true" class="v-icon notranslate v-icon--left theme--dark" style="font-size: 22px; height: 22px; width: 22px;">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg" style="font-size: 22px; height: 22px; width: 22px;">
              <path d="M21,16.5C21,16.88 20.79,17.21 20.47,17.38L12.57,21.82C12.41,21.94 12.21,22 12,22C11.79,22 11.59,21.94 11.43,21.82L3.53,17.38C3.21,17.21 3,16.88 3,16.5V7.5C3,7.12 3.21,6.79 3.53,6.62L11.43,2.18C11.59,2.06 11.79,2 12,2C12.21,2 12.41,2.06 12.57,2.18L20.47,6.62C20.79,6.79 21,7.12 21,7.5V16.5M12,4.15L10.11,5.22L16,8.61L17.96,7.5L12,4.15M6.04,7.5L12,10.85L13.96,9.75L8.08,6.35L6.04,7.5M5,15.91L11,19.29V12.58L5,9.21V15.91M19,15.91V9.21L13,12.58V19.29L19,15.91Z"></path>
            </svg>
          </span>
          <span>Apps</span>
          <span aria-hidden="true" class="v-icon notranslate theme--light" style="font-size: 20px; height: 20px; width: 20px;">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg" style="font-size: 20px; height: 20px; width: 20px;">
              <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"></path>
            </svg>
          </span>
        </span>
      </button>
      <div class="v-menu horizontal-nav-header-group v-menu--attached" eagers="">
        <!---->
      </div>
      <button type="button" class="text-capitalize text-base font-weight-regular px-6 v-btn v-btn--has-bg v-btn--rounded theme--light v-size--large transparent" role="button" aria-haspopup="true" aria-expanded="false">
        <span class="v-btn__content">
          <span aria-hidden="true" class="v-icon notranslate v-icon--left theme--dark" style="font-size: 22px; height: 22px; width: 22px;">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg" style="font-size: 22px; height: 22px; width: 22px;">
              <path d="M12 16.54L19.37 10.8L21 12.07L12 19.07L3 12.07L4.62 10.81L12 16.54M12 14L3 7L12 0L21 7L12 14M12 2.53L6.26 7L12 11.47L17.74 7L12 2.53M12 21.47L19.37 15.73L21 17L12 24L3 17L4.62 15.74L12 21.47"></path>
            </svg>
          </span>
          <span>User Interface</span>
          <span aria-hidden="true" class="v-icon notranslate theme--light" style="font-size: 20px; height: 20px; width: 20px;">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg" style="font-size: 20px; height: 20px; width: 20px;">
              <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"></path>
            </svg>
          </span>
        </span>
      </button>
      <div class="v-menu horizontal-nav-header-group v-menu--attached" eagers="">
        <!---->
      </div>
      <button type="button" class="text-capitalize text-base font-weight-regular px-6 v-btn v-btn--has-bg v-btn--rounded theme--light v-size--large transparent" role="button" aria-haspopup="true" aria-expanded="false">
        <span class="v-btn__content">
          <span aria-hidden="true" class="v-icon notranslate v-icon--left theme--dark" style="font-size: 22px; height: 22px; width: 22px;">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg" style="font-size: 22px; height: 22px; width: 22px;">
              <path d="M14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M18,20H6V4H13V9H18V20Z"></path>
            </svg>
          </span>
          <span>Pages</span>
          <span aria-hidden="true" class="v-icon notranslate theme--light" style="font-size: 20px; height: 20px; width: 20px;">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg" style="font-size: 20px; height: 20px; width: 20px;">
              <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"></path>
            </svg>
          </span>
        </span>
      </button>
      <div class="v-menu horizontal-nav-header-group v-menu--attached" eagers="">
        <!---->
      </div>
      <button type="button" class="text-capitalize text-base font-weight-regular px-6 v-btn v-btn--has-bg v-btn--rounded theme--light v-size--large transparent" role="button" aria-haspopup="true" aria-expanded="false">
        <span class="v-btn__content">
          <span aria-hidden="true" class="v-icon notranslate v-icon--left theme--dark" style="font-size: 22px; height: 22px; width: 22px;">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg" style="font-size: 22px; height: 22px; width: 22px;">
              <path d="M19,19H5V5H15V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V11H19M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z"></path>
            </svg>
          </span>
          <span>Forms &amp; Tables</span>
          <span aria-hidden="true" class="v-icon notranslate theme--light" style="font-size: 20px; height: 20px; width: 20px;">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg" style="font-size: 20px; height: 20px; width: 20px;">
              <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"></path>
            </svg>
          </span>
        </span>
      </button>
      <div class="v-menu horizontal-nav-header-group v-menu--attached" eagers="">
        <!---->
      </div>
      <button type="button" class="text-capitalize text-base font-weight-regular px-6 v-btn v-btn--has-bg v-btn--rounded theme--light v-size--large transparent" role="button" aria-haspopup="true" aria-expanded="false">
        <span class="v-btn__content">
          <span aria-hidden="true" class="v-icon notranslate v-icon--left theme--dark" style="font-size: 22px; height: 22px; width: 22px;">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg" style="font-size: 22px; height: 22px; width: 22px;">
              <path d="M13,2.05V5.08C16.39,5.57 19,8.47 19,12C19,12.9 18.82,13.75 18.5,14.54L21.12,16.07C21.68,14.83 22,13.45 22,12C22,6.82 18.05,2.55 13,2.05M12,19A7,7 0 0,1 5,12C5,8.47 7.61,5.57 11,5.08V2.05C5.94,2.55 2,6.81 2,12A10,10 0 0,0 12,22C15.3,22 18.23,20.39 20.05,17.91L17.45,16.38C16.17,18 14.21,19 12,19Z"></path>
            </svg>
          </span>
          <span>Charts &amp; Maps</span>
          <span aria-hidden="true" class="v-icon notranslate theme--light" style="font-size: 20px; height: 20px; width: 20px;">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg" style="font-size: 20px; height: 20px; width: 20px;">
              <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"></path>
            </svg>
          </span>
        </span>
      </button>
      <div class="v-menu horizontal-nav-header-group v-menu--attached" eagers="">
        <!---->
      </div>
      <button type="button" class="text-capitalize text-base font-weight-regular px-6 v-btn v-btn--has-bg v-btn--rounded theme--light v-size--large transparent" role="button" aria-haspopup="true" aria-expanded="false">
        <span class="v-btn__content">
          <span aria-hidden="true" class="v-icon notranslate v-icon--left theme--dark" style="font-size: 22px; height: 22px; width: 22px;">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg" style="font-size: 22px; height: 22px; width: 22px;">
              <path d="M16,12A2,2 0 0,1 18,10A2,2 0 0,1 20,12A2,2 0 0,1 18,14A2,2 0 0,1 16,12M10,12A2,2 0 0,1 12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12M4,12A2,2 0 0,1 6,10A2,2 0 0,1 8,12A2,2 0 0,1 6,14A2,2 0 0,1 4,12Z"></path>
            </svg>
          </span>
          <span>Others</span>
          <span aria-hidden="true" class="v-icon notranslate theme--light" style="font-size: 20px; height: 20px; width: 20px;">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg" style="font-size: 20px; height: 20px; width: 20px;">
              <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"></path>
            </svg>
          </span>
        </span>
      </button>
  </div>
</div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHomeOutline,
  mdiAlphaTBoxOutline,
  mdiEyeOutline,
  mdiCreditCardOutline,
  mdiTable,
  mdiFileOutline,
  mdiFormSelect,
  mdiAccountCogOutline,
} from '@mdi/js'
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  setup() {
    return {
      icons: {
        mdiHomeOutline,
        mdiAlphaTBoxOutline,
        mdiEyeOutline,
        mdiCreditCardOutline,
        mdiTable,
        mdiFileOutline,
        mdiFormSelect,
        mdiAccountCogOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
