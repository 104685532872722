<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800px"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
    >
      <v-card>
        <v-card-title>
          <v-col cols="5" md="5">
            <span class="text-h5">My Diary</span>
          </v-col>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-card
                color="grey lighten-1"
            >
              <div class="container">
                <v-form class="multi-col-validation" style="width: 100%;margin: 0 auto">
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-row>
                        <v-col
                          class="d-flex"
                          cols="4"
                        >
                          <v-select
                            v-model="daySelected"
                            :items="days"
                            label="Day"
                            outlined
                            dense
                          ></v-select>
                        </v-col>

                        <v-col
                          class="d-flex"
                          cols="4"
                        >
                          <v-select
                            v-model="monthSelected"
                            :items="months"
                            label="Month"
                            outlined
                            dense
                            @change="changeMonth()"
                          ></v-select>
                        </v-col>

                        <v-col
                          class="d-flex"
                          cols="4"
                        >
                          <v-select
                            v-model="yearSelected"
                            :items="years"
                            label="Year"
                            outlined
                            dense
                            @change="changeYear()"
                          ></v-select>
                          <v-tooltip top color="#000000" max-width="200px" v-if="!$vuetify.breakpoint.mdAndDown">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                :class="{'alternate-icon-small': !mdiInformationOutline}"
                                class="mx-auto icon-info-date"
                                v-bind="attrs"
                                v-on="on"
                              >
                                {{ mdiInformationOutline }}
                              </v-icon>
                            </template>
                            <span>If you only select the year. The day and month will be set to January 1st.</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        placeholder="Type the title"
                        tabindex="1"
                        v-model="title"
                        label="Title"
                        outlined
                        dense
                        class="txt-title"
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="editor-my-diary">
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <wysiwyg v-model="description" tabindex="2" ref="wysiwyg" />
                    </v-col>
                  </v-row>
                </v-form>
              </div>
            </v-card>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <!-- <v-btn color="primary" class="mt-5 alight-right btn-gallery" @click="callGallery()">
            <v-icon dark :class="{'alternate-icon-small': !mdiImageSearch}" class="mx-auto">{{ mdiImageSearch }}</v-icon>
          </v-btn> -->
          <v-spacer></v-spacer>
          <v-btn
            @click="saveDiary(status)"
            :loading="loading4"
            :disabled="loading4" color="primary">
            Save
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>{{ mdiCached }}</v-icon>
              </span>
            </template>
          </v-btn>
          <v-btn
            @click="$store.commit('showDiaryModal', false)">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import axios from "axios";
  import { mdiInformationOutline, mdiCloudUpload, mdiCached, mdiImageSearch } from '@mdi/js';

  export default {
    props: ['categories', 'categoriesArray', 'content_type'],
    data () {
      return {
        status: false,
        current_date: (new Date().toLocaleDateString('en-CA').slice(0, 19).replace('T', ' ').split(' ')[0].split('-')),
        dayActive: false,
        monthActive: false,
        days: [],
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June',	'July',	'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
        years: [],
        daySelected: null,
        monthSelected: null,
        yearSelected: null,

        /* ICONS */
        mdiInformationOutline,
        mdiCloudUpload,
        mdiCached,
        mdiImageSearch,

        loader: null,
        loading4: false,
        /* -- */
        dialog: true,
        title: '',
        description: '',
        endpoint: process.env.VUE_APP_ROOT_API,
        active: 1,
        logged: true,
        lastPosition: 0
      }
    },
    components: {
    },
    computed: {
      galleryItems() {
        return this.$store.state.galleryItems;
      }
    },
    watch: {
      galleryItems(val) {
        if(val) {
          this.galleryItems.forEach((value) => {
            document.execCommand("insertHTML", false, `<img src="${value.split('?X-Amz-Algorithm=AWS4')[0]}" style="display: block;width: 80%;margin: 0 auto;" />`);
          });
        }
      },
      loader () {
        const l = this.loader
        this[l] = !this[l]
        setTimeout(() => (this[l] = false), 3000)
        this.loader = null
      },
    },
    mounted() {
      var elm = document.querySelector('[contenteditable]');
      elm.addEventListener('click', () => {
        this.lastPosition = this.getCaretCharacterOffsetWithin(document.querySelector('[contenteditable]'));
      });

      elm.addEventListener('keydown', () => {
        this.lastPosition = this.getCaretCharacterOffsetWithin(document.querySelector('[contenteditable]'));
      });
      this.monthSelected = this.months[parseInt(this.current_date[1], 10) - 1];
      this.generateArrayOfYears();

      if(typeof this.$store.state.diary !== "boolean") {
        const diaryData = this.$store.state.diary;
        this.status = true;
        this.title = diaryData.title;
        this.description = diaryData.description;
        const selected_date = diaryData.selected_date.split('T')[0].split('-');
        this.yearSelected = parseInt(selected_date[0], 10);
        this.monthSelected = this.months[parseInt(selected_date[1], 10) - 1];
        this.daySelected = parseInt(selected_date[2], 10);
      }

      const vwBtnImage = document.querySelector('.vw-btn-image');
      if(vwBtnImage) {
        vwBtnImage.addEventListener('click', (e) => {
          e.preventDefault();
          e.stopPropagation();
          this.callGallery();
        });
      }
    },
    methods: {
      getCaretCharacterOffsetWithin(element) {
        var caretOffset = 0;
        var doc = element.ownerDocument || element.document;
        var win = doc.defaultView || doc.parentWindow;
        var sel;
        if (typeof win.getSelection != "undefined") {
          sel = win.getSelection();
          if (sel.rangeCount > 0) {
              var range = win.getSelection().getRangeAt(0);
              var preCaretRange = range.cloneRange();
              preCaretRange.selectNodeContents(element);
              preCaretRange.setEnd(range.endContainer, range.endOffset);
              caretOffset = preCaretRange.toString().length;
          }
        } else if ( (sel = doc.selection) && sel.type != "Control") {
          var textRange = sel.createRange();
          var preCaretTextRange = doc.body.createTextRange();
          preCaretTextRange.moveToElementText(element);
          preCaretTextRange.setEndPoint("EndToEnd", textRange);
          caretOffset = preCaretTextRange.text.length;
        }
        return caretOffset;
      },
      callGallery() {
        this.$store.commit('showGallery', {
          open: true,
          list: false
        });
      },
      setCaretPosition(el, pos) {
        const self = this;
        for(var node of el.childNodes){
          // if(node.nodeType == 3) { // we have a text node
            if(node.length >= pos) {
              var range = document.createRange(),
                  sel = window.getSelection();
              range.setStart(node,pos);
              range.collapse(true);
              sel.removeAllRanges();
              sel.addRange(range);
              return -1;
            } else {
              pos -= node.length;
            }
        }
        return pos;
      },
      getDiary() {
        const self = this;
        axios.get(`${self.endpoint}/getDiary`, {
          headers : {
            'Authorization': this.$store.state.jwtToken
          }
        }).then(response => {
          self.diary = response.data.content;
          self.deleting = Array.from({length: self.diary.length}, (_, i) => false);
        })
        .catch(() => { /* ERROR */ });
      },
      saveDiary(edit) {
        const self = this;
        this.loader = 'loading4';
        if(edit) {
          axios.post(`${self.endpoint}/updateDiary`, {
            title: this.title,
            description: this.description,
            id: this.$store.state.diary.id,
            selected_date: `${this.yearSelected}-${String(parseInt(this.months.indexOf(this.monthSelected), 10) + 1).padStart(2, '0')}-${String(this.daySelected).padStart(2, '0')}`,
          }, {
            headers : {
              'Authorization': this.$store.state.jwtToken
            }
          }).then(responseDB => {
            this.$store.commit('showDiaryModal', false);
          });
        } else {
          if(this.title.length || this.description.length) {
            axios.post(`${self.endpoint}/insertDiary`, {
              title: this.title,
              description: this.description,
              date: `${this.yearSelected}-${String(parseInt(this.months.indexOf(this.monthSelected), 10) + 1).padStart(2, '0')}-${String(this.daySelected).padStart(2, '0')}`,
            }, {
              headers : {
                'Authorization': this.$store.state.jwtToken
              }
            }).then(responseDB => {
              this.$store.commit('showDiaryModal', false);
            });
          } else {
            this.$store.commit('showDiaryModal', false);
          }
        }
      },
      changeMonth() {
        this.getDaysInMonthUTC(1, parseInt(this.months.indexOf(this.monthSelected), 10) + 1, this.yearSelected);
      },
      changeYear() {
        this.monthSelected = this.months[0];
        this.getDaysInMonthUTC(1, 0, this.yearSelected);
      },
      getDaysInMonthUTC(day, month, year) {
        this.days = [];
        var max = new Date(year, month, 0).getDate();
        for (var i = 1; i <= max; i++) {
          this.days.push(i);
        };
        this.daySelected = day;
      },
      generateArrayOfYears() {
        const max = new Date().getFullYear();
        const min = max - 110;
        this.years = [];

        for (var i = max; i >= min; i--) {
          this.years.push(i);
        }
        this.yearSelected = parseInt(this.current_date[0], 10);
        this.getDaysInMonthUTC(parseInt(this.current_date[2], 10), parseInt(this.current_date[1], 10), this.yearSelected);
      },
      modalClose(status) {
        this.$emit('closingModal', true);
      },
    }
  }
</script>

<style scoped lang="scss">
  .icon-info-date {
    position: relative;
    top: -2px;
    left: 5px;
  }
  .txt-title {
    width: 90%;
    float: right;
  }

  .col-4 {
    padding: 5px;
  }

  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }

  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
