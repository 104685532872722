<template>
  <div class="footer">
    <div class="container boxed-container w-full">
      <div class="d-flex justify-space-between">
        <div class="container">
          <div class="row">
            <div class="col">
              <h2 class="app-title text--primary">
                <v-img
                  :src="require('@/assets/images/logos/selfbio-logo-6.png')"
                  max-height="30px"
                  max-width="30px"
                  alt="logo"
                  contain
                  eager
                  class="app-logo me-3" style="transform: scale(1.7); opacity: 0.9;"
                ></v-img>
                <span style="margin-left: 12px; padding-top: 7px;display: inline-block;"><span style="color: #9155fd">SELF</span>BIOGRAPHY</span>
              </h2>
            </div>
            <div class="col">
              <div class="social-links">
                <a href="javascript:;">
                  <v-tooltip
                    top
                    color="#000000"
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        :class="{'alternate-icon-small': !mdiFacebook}"
                        class="mx-auto"
                        v-on="on"
                      >
                        {{ mdiFacebook }}
                      </v-icon>
                    </template>
                    <span>&nbsp;Coming Soon&nbsp;</span>
                  </v-tooltip>
                </a>
                <a href="javascript:;">
                  <v-tooltip
                    top
                    color="#000000"
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        :class="{'alternate-icon-small': !mdiTwitter}"
                        class="mx-auto"
                        v-on="on"
                      >
                        {{ mdiTwitter }}
                      </v-icon>
                    </template>
                    <span>&nbsp;Coming Soon&nbsp;</span>
                  </v-tooltip>
                </a>
                <a href="javascript:;">
                  <v-tooltip
                    top
                    color="#000000"
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        :class="{'alternate-icon-small': !mdiInstagram}"
                        class="mx-auto"
                        v-on="on"
                      >
                        {{ mdiInstagram }}
                      </v-icon>
                    </template>
                    <span>&nbsp;Coming Soon&nbsp;</span>
                  </v-tooltip>
                </a>
                <a href="javascript:;">
                  <v-tooltip
                    top
                    color="#000000"
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        :class="{'alternate-icon-small': !mdiLinkedin}"
                        class="mx-auto"
                        v-on="on"
                      >
                        {{ mdiLinkedin }}
                      </v-icon>
                    </template>
                    <span>&nbsp;Coming Soon&nbsp;</span>
                  </v-tooltip>
                </a>
                <a href="javascript:;">
                  <v-tooltip
                    top
                    color="#000000"
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        :class="{'alternate-icon-small': !mdiTelegram}"
                        class="mx-auto"
                        v-on="on"
                      >
                        {{ mdiTelegram }}
                      </v-icon>
                    </template>
                    <span>&nbsp;Coming Soon&nbsp;</span>
                  </v-tooltip>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="container row wrapper-menu-footer">
      <div class="footer-col">
        <h4 class="text-overline">About</h4>
        <ul>
          <li>
            <a href="javascript:;">
              <v-tooltip
                right
                color="#000000"
              >
                <template v-slot:activator="{ on }">
                  <span v-on="on">Self Biography</span>
                  <v-icon v-if="$store.state.level == 1" @click="editPage(400)" class="mx-auto">{{ mdiPlaylistEdit }}</v-icon>
                </template>
                <span>&nbsp;Coming Soon&nbsp;</span>
              </v-tooltip>
            </a>
          </li>
          <li>
            <a href="javascript:;">
              <v-tooltip
                right
                color="#000000"
              >
                <template v-slot:activator="{ on }">
                  <span v-on="on">Credits and Gratitude</span>
                  <v-icon v-if="$store.state.level == 1" @click="editPage(401)" class="mx-auto">{{ mdiPlaylistEdit }}</v-icon>
                </template>
                <span>&nbsp;Coming Soon&nbsp;</span>
              </v-tooltip>
            </a>
          </li>
          <li>
            <a href="javascript:;">
              <v-tooltip
                right
                color="#000000"
              >
                <template v-slot:activator="{ on }">
                  <span v-on="on">The Company</span>
                  <v-icon v-if="$store.state.level == 1" @click="editPage(402)" class="mx-auto">{{ mdiPlaylistEdit }}</v-icon>
                </template>
                <span>&nbsp;Coming Soon&nbsp;</span>
              </v-tooltip>
            </a>
          </li>
          <li>
            <a href="javascript:;">
              <v-tooltip
                right
                color="#000000"
              >
                <template v-slot:activator="{ on }">
                  <span v-on="on">Security</span>
                  <v-icon v-if="$store.state.level == 1" @click="editPage(403)" class="mx-auto">{{ mdiPlaylistEdit }}</v-icon>
                </template>
                <span>&nbsp;Coming Soon&nbsp;</span>
              </v-tooltip>
            </a>
          </li>
          <li>
            <a href="javascript:;">
              <v-tooltip
                right
                color="#000000"
              >
                <template v-slot:activator="{ on }">
                    <span v-on="on">Web3 / Blockchain</span>
                    <v-icon v-if="$store.state.level == 1" @click="editPage(404)" class="mx-auto">{{ mdiPlaylistEdit }}</v-icon>
                </template>
                <span>&nbsp;Coming Soon&nbsp;</span>
              </v-tooltip>
            </a>
          </li>
          <li>
            <a href="javascript:;">
              <v-tooltip
                right
                color="#000000"
              >
                <template v-slot:activator="{ on }">
                  <span v-on="on">Token</span>
                  <v-icon v-if="$store.state.level == 1" @click="editPage(405)" class="mx-auto">{{ mdiPlaylistEdit }}</v-icon>
                </template>
                <span>&nbsp;Coming Soon&nbsp;</span>
              </v-tooltip>
            </a>
          </li>
        </ul>
      </div>

      <div class="footer-col">
        <h4 class="text-overline">Resources</h4>
        <ul>
          <li>
            <a href="javascript:;">
              <span @click="$store.commit('setAgreements', true);">User Agreements</span>
              <v-icon v-if="$store.state.level == 1" @click="editPage(411)" class="mx-auto">{{ mdiPlaylistEdit }}</v-icon>
            </a>
          </li>
          <li>
            <a href="javascript:;">
              <v-tooltip
                right
                color="#000000"
                >
                  <template v-slot:activator="{ on }">
                    <span v-on="on">Documentation</span>
                    <v-icon v-if="$store.state.level == 1" @click="editPage(406)" class="mx-auto">{{ mdiPlaylistEdit }}</v-icon>
                  </template>
                <span>&nbsp;Coming Soon&nbsp;</span>
              </v-tooltip>
            </a>
          </li>
          <li>
            <a href="javascript:;">
              <v-tooltip
                right
                color="#000000"
                >
                  <template v-slot:activator="{ on }">
                    <span v-on="on">FAQ</span>
                    <v-icon v-if="$store.state.level == 1" @click="editPage(407)" class="mx-auto">{{ mdiPlaylistEdit }}</v-icon>
                  </template>
                <span>&nbsp;Coming Soon&nbsp;</span>
              </v-tooltip>
            </a>
          </li>
          <li>
            <a href="javascript:;">
              <v-tooltip
                right
                color="#000000"
                >
                  <template v-slot:activator="{ on }">
                    <span v-on="on">Support</span>
                    <v-icon v-if="$store.state.level == 1" @click="editPage(408)" class="mx-auto">{{ mdiPlaylistEdit }}</v-icon>
                  </template>
                <span>&nbsp;Coming Soon&nbsp;</span>
              </v-tooltip>
            </a>
          </li>
          <li>
            <a href="javascript:;">
              <v-tooltip
                right
                color="#000000"
                >
                  <template v-slot:activator="{ on }">
                    <span v-on="on">Metamask</span>
                    <v-icon v-if="$store.state.level == 1" @click="editPage(409)" class="mx-auto">{{ mdiPlaylistEdit }}</v-icon>
                  </template>
                <span>&nbsp;Coming Soon&nbsp;</span>
              </v-tooltip>
            </a>
          </li>
        </ul>
      </div>

      <div class="footer-col">
        <h4 class="text-overline">Pricing</h4>
        <ul>
          <li>
            <a href="/pages/pricing">Plans</a>
          </li>
          <li>
            <a href="javascript:;">
              <v-tooltip
                right
                color="#000000"
                >
                  <template v-slot:activator="{ on }">
                    <span v-on="on">Crypto Plans</span>
                    <v-icon v-if="$store.state.level == 1" @click="editPage(414)" class="mx-auto">{{ mdiPlaylistEdit }}</v-icon>
                  </template>
                <span>&nbsp;Coming Soon&nbsp;</span>
              </v-tooltip>
            </a>
          </li>
        </ul>
      </div>

      <div class="footer-col">
        <h4 class="text-overline">Social</h4>
        <ul>
          <li>
            <a href="javascript:;">
              <v-tooltip
                right
                color="#000000"
                >
                  <template v-slot:activator="{ on }">
                    <span v-on="on">Facebook</span>
                  </template>
                <span>&nbsp;Coming Soon&nbsp;</span>
              </v-tooltip>
            </a>
          </li>
          <li>
            <a href="javascript:;">
              <v-tooltip
                right
                color="#000000"
                >
                  <template v-slot:activator="{ on }">
                    <span v-on="on">Twitter</span>
                  </template>
                <span>&nbsp;Coming Soon&nbsp;</span>
              </v-tooltip>
            </a>
          </li>
          <li>
            <a href="javascript:;">
              <v-tooltip
                right
                color="#000000"
                >
                  <template v-slot:activator="{ on }">
                    <span v-on="on">LinkedIn</span>
                  </template>
                <span>&nbsp;Coming Soon&nbsp;</span>
              </v-tooltip>
            </a>
          </li>
          <li>
            <a href="javascript:;">
              <v-tooltip
                right
                color="#000000"
                >
                  <template v-slot:activator="{ on }">
                    <span v-on="on">Telegram</span>
                  </template>
                <span>&nbsp;Coming Soon&nbsp;</span>
              </v-tooltip>
            </a>
          </li>
          <li>
            <a href="javascript:;">
              <v-tooltip
                right
                color="#000000"
                >
                  <template v-slot:activator="{ on }">
                    <span v-on="on">Discord</span>
                  </template>
                <span>&nbsp;Coming Soon&nbsp;</span>
              </v-tooltip>
            </a>
          </li>
        </ul>
      </div>
      <ModalPages :page="page" @page_id="callPage" v-if="$store.state.edit_pages && $store.state.level == 1" />
      <Agreements class="mb-6" v-if="$store.state.agreements" />
      <ModalGallery class="mb-6" v-if="$store.state.gallery.open" />
    </div>
  </div>
</template>
<script>
import { mdiFacebook, mdiTwitter, mdiInstagram, mdiLinkedin, mdiTelegram, mdiDiscord, mdiPlaylistEdit } from '@mdi/js';
import ModalPages from '@/components/modals/ModalPages.vue';
import Agreements from '@/components/Agreements.vue';
import ModalGallery from '@/components/modals/ModalGallery.vue'

export default ({
  data() {
    return {
      mdiFacebook,
      mdiTwitter,
      mdiInstagram,
      mdiLinkedin,
      mdiTelegram,
      mdiDiscord,
      mdiPlaylistEdit,
      page: null,
    }
  },
  components: {
    ModalPages,
    ModalGallery,
    Agreements
  },
  methods: {
    callPage(page) {

      if(page === 410) {
        this.$router.push({path: `/pages/pricing`})
      } else {
        this.$router.push({path: `/page/${page}`}).catch(()=>{
          this.$router.go();
        });
      }
    },
    editPage(page) {
      this.page = page;
      this.$store.commit('showEditPages', true);
    }
  }
})
</script>

<style scoped lang="scss">
.footer {
  ul {
    padding: 0;
  }

  padding: 0 0 50px;
  width: 100%;

  .col,
  .footer-col {
    width: 25%;
    padding: 0 15px;
  }

  .col {
    width: 50%;
  }

  h4 {
    font-size: 22px;
    color: #ffffff;
    text-transform: capitalize;
    margin-bottom: 35px;
    font-weight: 500;
    position: relative;
  }

  h4::before {
    content: '';
    position: absolute;
    left:0;
    bottom: -10px;
    background-color: #9155fd;
    height: 2px;
    box-sizing: border-box;
    width: 50px;
  }

  ul li:not(:last-child) {
    margin-bottom: 10px;
  }

  ul li a {
    font-size: 14px;
    text-transform: capitalize;
    text-decoration: none;
    font-weight: 300;
    color: rgba(94, 86, 105, 0.87);
    display: block;
    transition: all 0.3s ease;

    span {
      margin-right: 5px;
    }
  }

  ul li a:hover {
    padding-left: 8px;
    text-decoration: underline;
  }

  .social-links {
    float: right;
    padding: 20px 0 16px;
    margin-right: -10px;
  }

  .social-links a {
    display: inline-block;
    height: 40px;
    width: 40px;
    background-color: rgba(255,255,255,0.2);
    margin: 0 10px 10px 0;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    transition: all 0.5s ease;

    &:last-child {
      margin-right: 0;
    }
  }

  .social-links a:hover {
    color: #24262b;
    background-color: #ffffff;
  }

  .boxed-container, .wrapper-menu-footer {
    max-width: 1420px;
    margin-left: auto;
    margin-right: auto;
    padding: 0;

    .container {
      padding: 0;
    }
  }

  .wrapper-menu-footer {
    position: relative;
    left: 20px;
    padding-left: 8%;
    width: 100%;
    margin: 0 auto;
    padding-top: 30px;
  }

  hr {
    border: 0;
    border-top: 1px solid #ccc;
  }

  .app-title {
    margin: 20px 0 30px;
  }

  .app-logo {
    float: left;
    position: relative;
    top: 5px;
  }
}

/*responsive*/
@media(max-width: 940px) {
  .footer {
    position: static !important;
    .wrapper-menu-footer {
      left: 0;
      padding-left: 5%;
    }

    .col {
      width: 100%;
      flex-basis: initial;
    }
    .footer-col {
      width: 100%;
      margin-bottom: 30px;
      padding: 0;

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul {
      padding-left: 30px;
    }

    .social-links,
    .app-title {
      float: none;
      margin: 0 auto;
      width: 290px;
    }

    .app-title {
      width: 220px;
      margin-top: 30px;
    }
  }

  .v-main {
    padding-bottom: 0 !important;
  }
}

@media(max-width: 574px) {
  .footer-col {
    width: 100%;
  }
}
</style>
