<template>
  <v-row justify="center">
    <v-dialog class="modal-gallery"
      persistent
      max-width="1400px"
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
    >
      <v-card>
        <!-- <v-card-title>
          <span class="text-h5">Gallery</span>
        </v-card-title> -->
        <v-card-text gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)">
          <v-container style="height: 400px;" v-if="deletingFile">
            <v-row
              class="fill-height"
              align-content="center"
              justify="center"
            >
              <v-col v-if="!loading"
                class="text-subtitle-1 text-center"
                cols="12"
              >
                {{ result ? 'The file has been deleted.' : 'Are you sure you want to delete this file?' }}
              </v-col>
              <v-col cols="12" class="justify-content-center wrapper-buttons-center" v-if="!loading && !result">
                <v-btn color="primary" @click="submitDelete()">Delete</v-btn>
                <v-btn @click="$emit('closingModal', true)">Cancel</v-btn>
              </v-col>

              <v-col v-if="loading"
                class="text-subtitle-1 text-center"
                cols="12"
              >
                Deleting file...
              </v-col>
              <v-col cols="6" v-if="loading">
                <v-progress-linear
                  color="primary accent-4"
                  indeterminate
                  rounded
                  height="6"
                ></v-progress-linear>
              </v-col>
            </v-row>
          </v-container>

          <div v-if="$vuetify.breakpoint.mdAndDown">
            menu mobile
          </div>

          <ContentCategories @filterCategory="filterCategory" v-if="(galleryList && !resultUpload && !deletingFile) && !$vuetify.breakpoint.mdAndDown" />
          <!-- UPLOAD CONTAINER -->
          <v-row class="fill-height"
            align-content="center"  v-if="loading && !deletingFile"
            justify="center" style="height: 400px">
            <v-col cols="4" class="align-center">
              <v-progress-linear
                color="primary accent-4"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <v-container v-if="!galleryList && !resultUpload && !loading">
            <v-card
              color="grey lighten-1"
              height="500px"
            >
              <div class="container">
                <div class="row justify-content-center" v-show="!resultUpload" v-bind:class="{ loading: loading }">
                  <div class="col-lg-12">
                    <drag-and-drop @filed="fileLoaded" />
                  </div>
                </div>
              </div>
            </v-card>
          </v-container>

          <!-- UPLOAD CONTAINER -->
          <v-container v-if="!deletingFile && galleryList && !loading" class="wrapper-multiple-content">
            <v-item-group multiple>
              <v-container>
                <v-row v-if="!filesUser.length && !loading">
                  <v-col class="text-subtitle-1 text-center wrapper-images" cols="12">
                    There is no content to display.<br />
                    Please select another category.
                  </v-col>
                </v-row>
                <v-row v-if="filesUser.length">
                  <v-col v-for="(file, key) in filesUser" :key="key" :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2" class="wrapper-image">
                    <label :for="`chk-${key}`">
                      <v-item v-slot="{ active, toggle }">
                        <v-card
                          :color="active ? 'primary' : ''"
                          class="d-flex align-center img-container"
                          dark
                          height="200"
                        >
                          <v-icon v-if="existFiles.includes(file.split('.com/')[1].split('?X-Amz')[0])"
                            medium
                            color="darken-2 icon-book"
                          >

                            {{ mdiBookLock }}
                          </v-icon>
                          <span class="wrapper-checkbox"> <!--  v-if="$store.state.diary || $store.state.modal_edit || $store.state.edit_pages || $store.state.modal_upload" -->
                            <span aria-hidden="true" class="v-icon notranslate theme--dark white--text" v-if="active">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg">
                                <path d="M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M19,3H5C3.89,3 3,3.89 3,5V19C3,20.1 3.9,21 5,21H19C20.1,21 21,20.1 21,19V5C21,3.89 20.1,3 19,3Z"></path>
                              </svg>
                            </span>

                            <span aria-hidden="true" class="v-icon notranslate theme--dark" v-if="!active">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg">
                                <path d="M19,3H5C3.89,3 3,3.89 3,5V19C3,20.1 3.9,21 5,21H19C20.1,21 21,20.1 21,19V5C21,3.89 20.1,3 19,3M19,5V19H5V5H19Z"></path>
                              </svg>
                            </span>
                            <input type="checkbox" :id="`chk-${key}`" v-model="selected" :value="file" @click="toggle" />
                          </span>

                          <v-icon v-if="!existFiles.includes(file.split('.com/')[1].split('?X-Amz')[0])"
                              dark
                              :class="{'alternate-icon-small': !mdiTrashCan}"
                              class="mx-auto btn-remove" @click="deleteFile(file)"
                          >
                              {{ mdiTrashCan }}
                          </v-icon>
                          <img v-if="file.indexOf('webp') !== -1" class="preview drag-and-drop-preview" :src="file" />
                          <video width="100%" height="100%" controls v-else>
                            <source :src="file" type="video/mp4">
                            Your browser does not support the video tag.
                          </video>
                        </v-card>
                      </v-item>
                    </label>
                  </v-col>
                </v-row>
              </v-container>
            </v-item-group>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" v-if="!loading && !result"
            @click="$store.state.gallery.list = !$store.state.gallery.list;resultUpload = false;fileUploaded = []"
          >
            {{ galleryList ? $vuetify.breakpoint.mdAndDown ? 'Upload&nbsp;&nbsp;' : 'Upload new Files&nbsp;&nbsp;' : $store.state.diary ? 'From Gallery&nbsp;&nbsp;' : 'Gallery&nbsp;&nbsp;' }}
            <v-icon right dark :class="{'alternate-icon-small': galleryList ? !mdiCloudUpload : !mdiImageSearch }" class="mx-auto">
              {{ galleryList ? mdiCloudUpload : mdiImageSearch }}
            </v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <div class="wrapper-pagination" v-if="galleryList && !resultUpload && !loading && !deletingFile">
            <!-- <button type="button" class="v-pagination__navigation v-pagination-scroll-left v-pagination__navigation--disabled"><span aria-hidden="true" class="v-icon notranslate theme--light"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg"><path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"></path></svg></span></button> -->
            <!-- <ul class="pagination-list">
              <li v-for="(item, ix) in 5" :key="ix"><a href="javascript:;" class="v-btn v-pagination__item v-pagination__item--active primary">{{ix + 1}}</a></li>
            </ul> -->
            <!-- <button type="button" class="v-pagination__navigation v-pagination-scroll-right v-pagination__navigation--disabled"><span aria-hidden="true" class="v-icon notranslate theme--light"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg"><path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"></path></svg></span></button> -->
          </div>
          <v-spacer></v-spacer>
          <v-btn color="primary"
            @click="submitFiles()" v-if="!galleryList && fileUploaded.length && !resultUpload && !loading"
          >
            Submit Files
          </v-btn>
          <v-btn color="primary"
            v-if="selected.length && galleryList && !loading"
            @click="relateFiles()">Import</v-btn>
          <v-btn
            v-if="!loading && !result"
            @click="$store.commit('showGallery', { open: false, list: false });">Close</v-btn>
          <v-btn
            v-if="!loading && result"
            @click="back()">
              Back
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import axios from "axios";
  import { mdiMagnifyScan, mdiTrashCan, mdiBookLock, mdiCloudUpload, mdiImageSearch } from '@mdi/js';
  import DragAndDrop from '@/components/DragAndDrop.vue';
  import ContentCategories from "@/components/ContentCategories.vue";

  export default {
    props: ['modals', 'actions'],
    data () {
      return {
        mdiMagnifyScan,
        mdiBookLock,
        mdiTrashCan,
        mdiCloudUpload,
        mdiImageSearch,

        deletingFile: false,
        dialog: true,
        s3: 'https://self-biography-system.s3.us-east-2.amazonaws.com/',
        endpoint: process.env.VUE_APP_ROOT_API,
        loading: false,
        result: false,
        filesUser: [],
        selected: [],
        existFiles: [],
        deleteUrl: null,

        loading: false,
        uploadPercentage: 0,
        resultUpload: false,
        uploader: false,
        fileUploaded: [],
        pagination: 1,
        qtdPages: 1,
        limitItems: 100,
        categorySelected: '',
        uncategorizedFiles: [],
      }
    },
    mounted() {
      this.getUserFiles();
    },
    watch: {
      uploader() {
        this.getUserFiles();
      },
      pagination(newPage) {
        if(newPage) {
          this.getUserFiles();
        }
      },
    },
    computed: {
      galleryList() {
        return this.$store.state.gallery.list;
      }
    },
    components: {
      DragAndDrop,
      ContentCategories
    },
    methods: {
      filterCategory(value, page) {
        this.categorySelected = value;
        this.getUserFiles(value);
      },
      fileLoaded(value) {
        this.fileUploaded = value;
      },
      relateFiles() {
        this.$store.commit('setGalleryItems', this.selected);
        this.$store.commit('showGallery', { open: false, list: false });
      },
      deleteFile(value) {
        this.deletingFile = true;
        this.deleteUrl = value;
      },
      formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
      },
      getUserFiles() {
        this.filesUser = [];
        this.existFiles = [];
        this.uncategorizedFiles = [];
        this.loading = true;
        axios.get(
          `${this.endpoint}/getS3Files?content_type=${this.$store.state.content_type}&category=${this.categorySelected}&limit=${this.pagination},${(this.limitItems)}`, {
          headers : {
            'Authorization': this.$store.state.jwtToken
          }
        }).then(response => {
          this.qtdPages = Math.ceil(response.data.total_files / this.limitItems);
          response.data.exist.forEach((value) => {
            this.existFiles.push(value.url);
          });
          response.data.content.forEach((value) => {
            if(!this.existFiles.includes(value.split('.com/')[1].split('?X-Amz')[0]))  {
              if(value.indexOf('.webp') !== -1) {
                this.uncategorizedFiles.push(value);
              }
            }
          });

          this.filesUser = this.categorySelected === -2 ? this.uncategorizedFiles : response.data.content;
          this.loading = false;
        })
        .catch(() => { /* ERROR */ });
      },
      submitDelete(url) {
        const self = this;
        self.loading = true;
        axios.post(`${self.endpoint}/deleteS3File`, {
          filename: self.deleteUrl.split('.com/')[1].split('?X-Amz')[0],
        }, {
          headers : {
            'Authorization': this.$store.state.jwtToken
          }
        }).then(responseDB => {
          self.loading = false;
          self.result = true;
        });
      },
      back(){
        this.result = false;
        this.deletingFile = false;
        this.resultUpload = false;
        this.filesUser.splice( this.filesUser.indexOf(this.deleteUrl), 1 );
      },
      formatString(str) {
        return str.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
      },
      submitFiles(draft) {
        this.loading = true;
        this.fileUploaded.forEach((elm) => {
          axios.post(`https://content.selfbiography.com/api/upload`, {
            buffer: elm.file,
            originalname: this.formatString((elm.name.substr(0, elm.name.lastIndexOf('.')) || elm.name).toLowerCase().trim().replace(/\s+/g, '-')),
            mime: elm.mimeType,
          }, {
            headers : {
            'Authorization': this.$store.state.jwtToken
          }}).then(response => {
            this.resultUpload = true;
            this.loading = false;
            this.uploader = !this.uploader;
            this.$store.state.gallery.list = !this.$store.state.gallery.list;
          })
          .catch(() => {
            console.log('error Upload');
          });
        });
      },
    },
  }
</script>

<style scoped lang="scss">

.modal-picture-details {
  max-width: 900px !important;
  img {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.2);
  }

  .spinner {
    margin: 20px 0 0 -25px;
  }
}
.modal-picture-details .row {
  position: relative;
}

.modal-picture-details .click-region {
  cursor: pointer;
  height: auto;
  position: relative;
  margin-bottom: 20px;

  button {
    display: block;
    background: none;
    position: absolute;
    right: 0px;
    bottom: 5px;
    z-index: 20;
  }
}

.wrapper-text {
  max-height: 50vh;
  overflow: hidden;
  overflow-y: auto;
}

.wrapper-buttons-center {
  text-align: center;
  button {
    margin: 0 10px;
  }
}

.wrapper-multiple-content {
  height: 66vh;
  overflow-y: auto;

  @media(max-width: 940px) {
    height: 100vh !important;
  }
}

.wrapper-image {
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 200px;
  margin: 10px auto;
  label {
    cursor: pointer;
  }
  img {
    line-height: 1.5;
    text-align: center;
    overflow-wrap: break-word;
    cursor: pointer;
    width: 100vw;
    height: auto;
    display: block;
    max-width: calc(100% - 20px);
    margin: 10px;
  }
  .v-icon {
    transform: scale(0.9);
    padding: 5px;
  }
}

.btn-remove,
.wrapper-checkbox,
.text--icon-book {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  padding: 0px;
  background: rgba(0,0,0,0.5);
  border-radius: 100% !important;
}

.text--icon-book {
  top: 10px;
}

.wrapper-checkbox {
  top: 50%;
  left: 10px;
  right: auto;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  margin: 0;

  .v-icon {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.wrapper-checkbox {
  input[type="checkbox"] {
    display: none;
  }
}

.v-application--is-ltr .v-input--selection-controls__input {
  margin-right: 8px;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
}

.v-pagination-scroll-left,
.v-pagination-scroll-right {
  position: absolute;
  left: 24%;
}

.v-pagination-scroll-right {
  left: auto;
  right: 20%;
  transform: rotate(180deg);
}

.pagination-list {
  li {
    list-style: none;
    float: left;
    margin: 0 10px;
  }
}
</style>
