import Vue from 'vue';
import Vuex from 'vuex';
import store from './../store';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    resetUpload: false,
    public_user: false,
    level: 2,
    logged: false,
    diary: false,
    gallery: {
      open: false,
      list: false
    },
    galleryItems: [],
    customCategories: false,
    reloadCategories: false,
    shareMemoryRequest: false,
    edit_pages: false,
    modal_upload: false,
    modal_edit: false,
    upload_biographies: false,
    content_categories: [],
    content_type: null,
    jwtToken: null,
    getFiles: 0,
    agreements: false,
    isMobile: false,
  },
  mutations: {
    setMobile(state, status) {
      state.isMobile = status;
    },
    setAgreements(state, status) {
      state.agreements = status;
    },
    getFiles(state) {
      state.getFiles = state.getFiles + 1;
    },
    setToken(state, token) {
      state.jwtToken = token
    },
    reloadCategories(state, status) {
      state.reloadCategories = !state.reloadCategories;
    },
    setUser(state, user) {
      state.user = user
    },
    setUploadBiographies(state, status) {
      state.upload_biographies = status;
    },
    resetUpload(state, status) {
      state.resetUpload = status;
    },
    setPublic(state, status) {
      state.public_user = status;
    },
    setLogin(state, status) {
      state.logged = status;
    },
    setLevel(state, status) {
      state.level = status;
    },
    setContentCategories(state, value) {
      state.content_categories = value;
    },
    setContentType(state, value) {
      state.content_type = value;
    },
    showGallery(state, status) {
      state.gallery = status;
    },
    showDiaryModal(state, status) {
      state.diary = status;
    },
    showUploadModal(state, status) {
      state.modal_upload = status;
    },
    showEditModal(state, status) {
      state.modal_edit = status;
    },
    showEditPages(state, status) {
      state.edit_pages = status;
    },
    showCustomCategories(state, status) {
      state.customCategories = status;
    },
    shareMemoryRequest(state, status) {
      state.shareMemoryRequest = status;
    },
    setGalleryItems(state, items) {
      state.galleryItems = items;
    },
  },
  getters: {
    isSignIn: (state) => {
      return state.user !== null
    },
  },
  actions: {
    async fetchToken({ commit }) {
      fetch("https://cognito-idp.us-east-2.amazonaws.com/", {
          headers: {
            "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
            "Content-Type": "application/x-amz-json-1.1",
          },
          mode: 'cors',
          cache: 'no-cache',
          method: 'POST',
          body: JSON.stringify({
            ClientId: "dkich7t8jtuoga1e3e86ac05t",
            AuthFlow: 'REFRESH_TOKEN_AUTH',
            AuthParameters: {
              REFRESH_TOKEN: localStorage.getItem('refreshToken'),
            }
          }),
      }).then(async (res) => {
        const result = await res.json();
        store.commit('setToken', result.AuthenticationResult.AccessToken);
        return true;
      }).catch((error) => {
        console.log('Invalid Token');
      });
    },
  },
  modules: {},
});
