<template>
  <v-container>
    <v-row>
      <v-col class="text-center" cols="12">
        <div class="tw-toggle">
          <input id="rdo-personal" type="radio" name="toggle" value="false" :checked="$store.state.content_type === 0" @click="setContentType(0)" />
          <label for="rdo-personal" class="toggle toggle-yes">
            <v-icon :class="{'alternate-icon-small': !mdiAccount}" class="mx-auto">
              {{ mdiAccount }}
            </v-icon>
          </label>
          <input id="rdo-both" type="radio" name="toggle" value="-1" @click="setContentType(-1)" :checked="$store.state.content_type === -1" />
          <label for="rdo-both" class="toggle toggle-yes">
            <v-icon :class="{'alternate-icon-small': !mdiAccountMultiple}" class="mx-auto">
              {{ mdiAccountMultiple }}
            </v-icon>
          </label>
          <input id="rdo-professional" type="radio" name="toggle" value="true" @click="setContentType(1)" :checked="$store.state.content_type === 1" />
          <label for="rdo-professional" class="toggle toggle-yes">
            <v-icon :class="{'alternate-icon-small': !mdiAccountTie}" class="mx-auto">
              {{ mdiAccountTie }}
            </v-icon>
          </label>
          <span></span>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <div class="d-flex justify-center wrapper-menu">
        <div class="inner-wrapper-menu"> <!-- v-if="active" -->
          <ul class="list-menu clearfix" v-if="!$store.state.content_type || $store.state.content_type === -1">
            <li v-for="(item) in categories" :key="`system-${item.id}`">
              <v-btn @click="searchCategory(item.id, 1)">
                {{ item.name }}
                <v-icon v-if="item.children"
                  :class="{'alternate-icon-small': !mdiChevronDown}"
                  class="mx-auto"
                >
                  {{ mdiChevronDown }}
                </v-icon>
              </v-btn>
              <ul v-if="item.children">
                <li v-for="(itemL1) in item.children" :key="`system-L-${itemL1.id}`">
                  <span @click="searchCategory(itemL1.id, 1)">
                    {{ itemL1.name }}
                    <v-icon v-if="itemL1.children"
                      :class="{'alternate-icon-small': mdiChevronDown}"
                      class="mx-auto arrow-icon"
                    >
                      {{ mdiChevronDown }}
                    </v-icon>
                  </span>
                  <ul v-if="itemL1.children">
                    <li v-for="(itemL2, index) in itemL1.children" :key="`system-L2-${index}`">
                      <span @click="searchCategory(itemL2.id, 1)">{{ itemL2.name }}</span>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li v-if="!$store.state.content_type">
              <v-btn @click="searchCategory(-1, 1)" v-bind:class="{ 'active': categorySelected === '' }" elevation="2">All</v-btn>
            </li>
            <li v-if="$store.state.content_type !== -1">
              <v-btn elevation="2" @click="searchCategory(-2, 1)">
                Uncategorized
              </v-btn>
            </li>
          </ul>

          <ul class="list-menu clearfix" v-if="$store.state.content_type || $store.state.content_type === -1">
            <li v-for="(item) in professionalCategories" :key="`system-${item.id}`">
              <v-btn @click="searchCategory(item.id, 1)">
                {{ item.name }}
                <v-icon v-if="item.children"
                  :class="{'alternate-icon-small': !mdiChevronDown}"
                  class="mx-auto"
                >
                  {{ mdiChevronDown }}
                </v-icon>
              </v-btn>
              <ul v-if="item.children">
                <li v-for="(itemL1) in item.children" :key="`system-L-${itemL1.id}`">
                  <span @click="searchCategory(itemL1.id, 1)">
                    {{ itemL1.name }}
                    <v-icon v-if="itemL1.children"
                      :class="{'alternate-icon-small': mdiChevronDown}"
                      class="mx-auto arrow-icon"
                    >
                      {{ mdiChevronDown }}
                    </v-icon>
                  </span>
                  <ul v-if="itemL1.children">
                    <li v-for="(itemL2, index) in itemL1.children" :key="`system-L2-${index}`">
                      <span @click="searchCategory(itemL2.id, 1)">{{ itemL2.name }}</span></li>
                  </ul>
                </li>
              </ul>
            </li>
            <li v-if="$store.state.content_type">
              <v-btn @click="searchCategory(-1, 1)" v-bind:class="{ 'active': categorySelected === '' }" elevation="2">All</v-btn>
            </li>
            <li>
              <v-btn elevation="2" @click="searchCategory(-2, 1)">
                Uncategorized
              </v-btn>
            </li>
          </ul>
        </div>
      </div>
    </v-row>
  </v-container>
</template>

<script>
  import axios from "axios";
  import { mdiChevronDown, mdiCloudUpload, mdiAccountTie, mdiAccount, mdiAccountMultiple } from '@mdi/js';

  export default {
    data: () => ({
      /* Icons */
      mdiChevronDown,
      mdiCloudUpload,
      mdiAccountTie,
      mdiAccount,
      mdiAccountMultiple,
      endpoint: process.env.VUE_APP_ROOT_API,
      categorySelected: '',
      categories: [],
      professionalCategories: []
    }),
    mounted() {
      this.setContentType(0);
      this.getCategories();
    },
    watch: {
      '$store.state.content_type'() {
        this.checkContentType();
      }
    },
    methods: {
      setContentType(val) {
        this.$store.commit('setContentType', val);
        this.searchCategory(-1, 1);
      },
      checkContentType() {
        if(!this.$store.state.content_type) {
          this.$store.commit('setContentCategories', this.categories);
        } else {
          this.$store.commit('setContentCategories', this.professionalCategories);
        }
      },
      searchCategory(value, page) {
        this.categorySelected = value !== -1 ? value : '';
        this.$emit('filterCategory', this.categorySelected, page);
      },
      getCategories() {
        const self = this;
        axios.get(
          `${self.endpoint}/getCategories`, {
            headers : {
              'Authorization': this.$store.state.jwtToken
            }
          }).then(response => {
          this.categories = [];
          this.professionalCategories = [];
          response.data.content.forEach(value => {
            if(value.type) {
              if (!value.content_type) {
                if(value.active) {
                  this.categories.push(value);
                }
              } else {
                if(value.active) {
                  this.professionalCategories.push(value);
                }
              }
            }
          });
          this.checkContentType();
        })
      }
    }
  }
</script>
