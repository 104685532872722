<template>
  <component :is="resolveLayout">
    <router-view></router-view>
    <ModalDiary class="mb-6" v-if="$store.state.diary" />
    <div class="action-buttons" v-if="$route.name !== 'LandingPage' && $route.name !== 'pages-login'">
      <v-btn
        fab
        dark
        small
        v-if="$store.state.upload_biographies"
        @click="$store.commit('showUploadModal', true);"
      >

        <v-icon>{{mdiCloudUpload}}</v-icon>
      </v-btn>
      <v-btn
        fab
        dark
        small
        @click="$store.commit('showGallery', { open: true, list: true });"
      >
        <v-icon>{{mdiImageSearchOutline}}</v-icon>
      </v-btn>
      <v-btn
        fab
        dark
        small
        @click="$store.commit('showDiaryModal', true);"
      >
        <!-- v-if="!$store.state.diary" -->
        <v-icon>{{mdiNotebookPlus}}</v-icon>
      </v-btn>
    </div>
  </component>
</template>

<script>
import { computed, onBeforeUpdate } from '@vue/composition-api';
import { useRouter } from '@/utils';
import LayoutBlank from '@/layouts/Blank.vue';
import LayoutContent from '@/layouts/Content.vue';
import ModalDiary from '@/components/modals/ModalDiary.vue';
import {
    mdiClose,
    mdiAccountCircle,
    mdiPencil,
    mdiPlus,
    mdiDelete,
    mdiImageSearchOutline,
    mdiNotebookPlus,
    mdiDesktopClassic,
    mdiCloudUpload
} from '@mdi/js';

export default {
  components: {
    LayoutBlank,
    LayoutContent,
    ModalDiary
  },
  watch: {
    '$route' (to, from) {
      this.$store.commit('setUploadBiographies', false);
    }
  },
  setup() {
    const { route } = useRouter()
    // onBeforeUpdate(() => {
    //   if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    //     store.commit('setMobile', true);
    //   } else {
    //     store.commit('setMobile', false);
    //   }
    // });

    const resolveLayout = computed(() => {
      // Handles initial route
      if (route.value.name === null) return null

      if (route.value.meta.layout === 'blank') return 'layout-blank'

      return 'layout-content'
    })

    return {
      resolveLayout,
      mdiClose,
      mdiAccountCircle,
      mdiPencil,
      mdiPlus,
      mdiDelete,
      mdiCloudUpload,
      mdiImageSearchOutline,
      mdiNotebookPlus,
      mdiDesktopClassic,
      direction: 'top',
      fab: false,
      fling: false,
      hover: false,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: 'slide-y-reverse-transition',
    }
  },
}
</script>

<style lang="scss">
  body {
    overflow: hidden;
  }

  .clearfix::before, .clearfix::after {
    content: ' ';
    display: table;
  }
  .clearfix::after {
  clear: both;
  }

  .app-content-container {
    > div {
      > .container {
        min-height: 52vh;
        margin-top: 0;
      }
    }
  }

  .editr {
    border-radius: 5px;
    margin-bottom: 15px;
    overflow: hidden;
    &[error='true'] {
      border-color: red;
      border-width: 2px;
    }
    label {
      font-size: 10px;
    }
    input[type='text'] {
      width: 30% !important;
      border: 1px solid #ccc !important;
    }
    button {
      font-size: 12px;
      padding: 0px 5px;
      background: #ccc;
      color: #000;
      border-radius: 4px;
      margin: 0 0 0 2px;
      position: relative;
      top: 1px;
    }
  }

  .editr--content {
    max-height: 150px;
  }

  .editor-my-diary {
    .editr--content {
      height: 50vh;
      max-height: 50vh;
    }
  }

  .editor-share-request {
    .editr--content {
      height: 350px !important;
      max-height: 280px !important;
    }
  }

  .modal-zoom {
    .v-carousel {
      .v-responsive__content {
        display: flex;
      }
    }
  }

  .modal-picture-details {
    .v-responsive__content {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .v-carousel {
    .v-carousel__controls {
      height: 35px;
      background: none;
    }
    .v-item-group {
      transform: scale(0.55);
    }
    .v-btn--icon.v-size--small {
      width: 6px !important;
      height: 6px !important;
      background: gray;
    }

    .v-btn.v-btn--icon {
      background: gray;
    }

    .v-item--active {
      transform: scale(1.5);
    }
  }

  .flag-container {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
    top: -5px;
    left: -5px;
    z-index: 1;

    .flag {
      width: 164px;
      padding: 7px;
      background-color: var(--v-primary-base);
      color: #fff;
      text-align: center;
      transform: rotate(-45deg) translate(-28%, -35%);
      box-shadow: 0px 5px 5px rgba(0,0,0,0.4);
      text-transform: uppercase;
      text-shadow: 0 2px 2px rgba(0,0,0,0.4);
    }
  }

  .v-speed-dial--right {
    width: 53px;
    position: fixed !important;
    right: 50px !important;
    bottom: 50px !important;
    z-index: 15 !important;
  }

  .my-biography {
    .v-window__prev, .v-window__next {
      margin: 0 26px !important;
    }
  }

  .router-link-active:not([href="/"]) {
    border-bottom: 2px solid #ccc;
    border-radius: 25px;
  }

  .v-list-item__content a {
    border: 0 !important;
    border-radius: none !important;
  }

  .v-stepper__wrapper {overflow: unset !important;}

  .my-biography {
    .box-slider {
      max-width: 100% !important;
      .v-item-group {
        height: 94% !important;
      }

      .v-responsive__content {
        height: 100vh;
        max-height: 415px;
        position: relative;

        @media(max-width: 940px) {
          display: flex;
        }
      }

      .v-window__container {
        height: 100vh;
        max-height: 390px;
      }
    }
  }

  /* Switch 3 Stages */
  .tw-toggle {
    display: inline-block;
    padding: 2px 3px;
    border-radius: 20px;
    position: relative;
    width: 150px;
    border: 2px solid #95A5A6;
  }

  .tw-toggle label {
    text-align: center;
    font-family: sans-serif;
    display: inline-block;
    color: #95A5A6;
    position:relative;
    z-index:2;
    margin: 0;
    text-align: center;
    padding: 2px 11px;
    font-size: 15px;
    cursor: pointer;
  }

  .tw-toggle input {
    position: absolute;
    z-index: 3;
    cursor: pointer;
    top: 10px;
    margin-left: 9px;
    display: none;
  }

  .tw-toggle > span {
    height: 26px;
    width: 26px;
    line-height: 26px;
    border-radius: 50%;
    background: rgb(145, 85, 253);
    display:block;
    position:absolute;
    left: 22px;
    top: 3px;
    transition:all 0.3s ease-in-out;
  }

  .tw-toggle input[value="false"]:checked ~ span {
    left: 14px;
  }

  .tw-toggle input[value="-1"]:checked ~ span {
    left: 60px;
  }

  .tw-toggle input[value="true"]:checked ~ span {
    left: 106px;
  }

  .tw-toggle input:checked + label .v-icon {
    color:#fff !important;
  }

  .rotate_images {
    &__position_0 {
      transform: rotate(0) scale(1);
    }

    &__position_1 {
      transform: rotate(90deg) scale(0.75);
    }

    &__position_2 {
      transform: rotate(180deg) scale(1);
    }

    &__position_3 {
      transform: rotate(270deg) scale(0.75);
    }
  }

  .item-description img {
    width: 100%;
    margin: 10px auto;
    border: 1px solid #ccc;
  }

  a.vw-btn-image + .dashboard {
    display: none !important;
  }

  @font-face {
    font-family: 'copperplatelight';
    src: url('./assets/fonts/copperplate-light-02-webfont.woff2') format('woff2'),
          url('./assets/fonts/copperplate-light-02-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  .app-title {
    font-family: 'copperplatelight';
    font-size: 18px !important;
    text-transform: uppercase;
  }

  body main.v-main:not(.landingpage) {
    padding: 64px 0px !important;
  }

  .v-main__wrap ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    border-radius: 50px;
  }

  .v-main__wrap ::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
  }

  .v-main__wrap ::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border: 1px solid #ccc;
    border-radius: 50px;
  }

  .v-treeview .v-icon__svg {
    width: auto;
    height: auto;
  }

  .action-buttons {
    position: fixed;
    bottom: 50px;
    right: 50px;
    z-index: 5;
    @media(max-width: 940px) {
      bottom: 2%;
      right: 6%;
    }
    .v-btn {
      display: block;
      margin: 10px 0;
    }
  }

  .wrapper-pagination {
    position: absolute;
    left: 52% !important;
    transform: translateX(-50%) !important;
    padding: 0 20px;
    max-width: 60% !important;
    .inner_wrapper-pagination {
      max-width: 100% !important;
      overflow-y: hidden;
      margin: 0 auto;

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
        background-color: #F5F5F5;
        border-radius: 50px;
      }

      &::-webkit-scrollbar {
        height: 5px;
        background-color: #F5F5F5;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border: 1px solid #ccc;
        border-radius: 50px;
      }
    }

    .v-pagination {
      justify-content: left;
      li:first-child {
        display: none !important;
      }

      .v-pagination__navigation {
        display: none !important;
      }
    }
  }

  .wrapper-menu {
    flex: 1;
    position: relative;
    .inner-wrapper-menu {
      text-align: center;
    }
    .v-btn {
      margin-bottom: 20px;
    }
  }

  .list-menu {
    margin: 0 0px !important;
    padding: 0 !important;
    justify-content: center !important;
    display: flex !important;
    li {
      text-align: left;
      list-style: none;
      cursor: pointer;
      position: relative;
      margin: 10px 0;
      ul {
        display: none;
        position: absolute;
        left: 0;
        width: auto;
        max-width: 300px;
        background: #fff;
        box-shadow: 0 4px 8px -4px rgb(94 86 105 / 42%);
        padding: 0;
        border-radius: 6px;
        top: 70%;
        z-index: 5;

        color:rgba(94, 86, 105, 0.87);
        text-transform: uppercase;
        font-size: 0.875rem;
        font-family: Inter, sans-serif;
        font-weight: 500;

        li {
          padding: 0 20px 0 15px;
          display: block;
          min-width: 150px;
          span {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .arrow-icon {
          transform: rotate(-90deg);
          position: absolute;
          right: -2px;
        }

        ul {
          left: 100%;
          top: -10px;
        }
      }
      &:hover {
        text-decoration: underline;
        > ul {
          display: block;
        }
      }
    }
    > li {
      float: left;
      margin: 0;
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  .drag-disabled {
    .list-group-item {
      cursor: default !important;
    }
  }

  .v-input--switch {
    .v-messages {
      display: none;
    }
  }

  @media(max-width: 940px) {
    html {
      height: 100vh;
    }
    .mobile--menu-list {
      width: 100%;
    }
    .inner-wrapper-menu .filter {
      top: 115px;
      z-index: 2;
    }
    .txt-title  {
      width: 100% !important;
    }
    .editor-my-diary .editr--content {
      max-height: 300px;
    }
    .editr--toolbar {
      height: 42px !important;
      overflow-x: scroll;
    }
    .diary--descriptions {
      .v-timeline-item {
        min-width: 0 !important;
      }
      .icon-edit {
        right: 11% !important;
      }

      .v-timeline-item__body {
        max-width: 100% !important;
        width: 100% !important;
      }
      .v-timeline:before,
      .v-timeline-item__divider,
      .v-timeline-item__opposite,
      .v-timeline-item__body .v-card::before,
      .v-timeline-item__body .v-card::after {
        display: none !important;
      }
    }

    .wrapper-menu {
      .v-list-item__title {
        text-align: left;
      }

      .inner-wrapper-menu {
        width: 100%;
      }
      .v-list-group__items .v-list-item {
        padding-left: 10px !important;
      }

      .v-list-group__items {
        .v-list-group__items .v-list-group__items {
          padding-left: 20px;
        }
        .single_item {
          margin-left: 8px;
          span {
            margin-right: 10px;
          }
        }
      }

      .v-list-group__items .v-list-group__items .v-list-item {
        padding-left: 40px !important;
      }
      .v-list-item__icon {
        margin-right: 2px !important;
      }
    }

    .v-timeline-item {
      padding: 30px 0 !important;
      &__divider {
        display: none !important;
      }

      &__opposite {
        width: 100vw;
        max-width: 100% !important;
      }
      .v-card--link:before, .v-card--link:after {
        display: none !important;
      }
    }

    .v-dialog {
      .v-card__actions {
        position: fixed;
        bottom: 0;
        width: 100%;
        background: #fff;
        z-index: 20;
      }

      .container {
        height: 58vh;
      }


      .v-carousel__controls {
        bottom: 65px;
      }

      .avatar-infos span {
        display: none;
      }

      .action-icons {
        position: relative;
        top: -58px;
      }

      .txt-date {
        font-size: 15px !important;
      }

      .wrapper-icons {
        right: 0px;
        top: -58px;
      }

      .static-detail .row {
        position: static !important;
      }

      .text-h5.modal-details--text-h5 {
        position: relative;
        top: -9px;
        left: 0;
        margin-left: 0 !important;
        text-align: center !important;
      }

      .wrapper-text {
        position: absolute !important;
        top: 101px;
        left: 0;
        background: #fff;
        height: 100%;
        z-index: 20;
        max-height: calc(100vh - 192px) !important;
      }

      .v-carousel {
        max-height: calc(100vh - 222px);
      }
    }
  }

  .LandingPage {
    &.v-footer {
      position: static !important;
    }
  }

.hydrated body:after {
  content: "Beta";
  position: fixed;
  width: 95px;
  height: 25px;
  background: #9155fd;
  top: 74px;
  left: -25px;
  text-align: center;
  font-size: 13px;
  font-family: Inter, sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  line-height: 27px;
  transform:rotate(-45deg);
  z-index: 4;


  @media(max-width: 940px) {
    top: 64px;
    left: -28px;
    width: 97px;
  }
}
</style>
